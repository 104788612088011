import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useEffect } from 'react';
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Compose from 'components/app/email/compose/Compose';
import axiosClient from 'config/axios';
import { getRelativeTime } from 'helpers/utils';

const File = ({ icon, title, action, actionIcon, className }) => {
  return (
    <div
      className={classNames(
        'border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs--1',
        className
      )}
    >
      <FontAwesomeIcon icon={icon} className="fs-1" />
      <span className="ms-2 me-3">{title}</span>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip style={{ position: 'fixed' }}>{action}</Tooltip>}
      >
        <a href="#!" className="text-300 ms-auto">
          <FontAwesomeIcon icon={actionIcon} />
        </a>
      </OverlayTrigger>
    </div>
  );
};

File.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  actionIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  className: PropTypes.string
};

const Mail = ({ message }) => {
  return (
    <div>
      {/* mail header */}
      <div className="d-flex flex-column flex-md-row justify-content-between mb-x1">
        <Flex alignItems="center" className="gap-2">
          <p className="mb-0">
            <Link
              to="/ai-support/contact-details"
              className="fw-semi-bold mb-0 text-800"
            >
              {message.sender}
            </Link>
          </p>
        </Flex>
        <p className="fs--2 fs-sm--1 fw-semi-bold mb-0">
          {format(new Date(message.created_at), 'd MMMM, yyyy')}
          <span className="mx-1">|</span>
          <span className="fst-italic">
            {format(new Date(message.created_at), 'hh:mm aa')}{' '}
            {` (${getRelativeTime(message.created_at)}) `}
          </span>

          <FontAwesomeIcon icon="star" className="ms-2 " />
        </p>
      </div>
      {/* mail body */}
      <div className="mb-3 pb-3 border-bottom">
        {message.content.split('\r\n').map(part => (
          <>
           <div dangerouslySetInnerHTML={{ __html: part }} />
          </>
        ))}
        {message.attached && (
          <div className="p-x1 bg-light rounded-3 mt-3">
            <div className="d-inline-flex flex-column">
              <File
                title="broken_tv_solve.jpg (873kb)"
                icon={['far', 'image']}
                action="Download"
                actionIcon="arrow-down"
                className="mb-2"
              />
              <File
                title="broken_tv_solve.zip (342kb)"
                icon="file-archive"
                action="Download"
                actionIcon="arrow-down"
              />
            </div>
            <hr className="my-x1" />
            <Row className="flex-between-center gx-4 gy-2">
              <Col xs="auto">
                <p className="fs--1 text-1000 mb-sm-0 font-sans-serif fw-medium mb-0">
                  <FontAwesomeIcon icon="link" className="me-2" />2 files
                  attached
                </p>
              </Col>
              <Col xs="auto">
                <IconButton
                  icon="file-download"
                  variant="falcon-default"
                  size="sm"
                >
                  <span className="ms-1">Download all</span>
                </IconButton>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

Mail.propTypes = {
  message: PropTypes.shape({
    img: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    salutation: PropTypes.string.isRequired,
    conclusion: PropTypes.string.isRequired,
    sender: PropTypes.string.isRequired,
    closing: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    attached: PropTypes.bool.isRequired
  }),
  index: PropTypes.number
};

const Ticket = ({ conversation }) => {
  const [showForm, setShowForm] = useState(false);
  const { ticketId } = useParams();
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const conversationMessageResponse = await axiosClient.get(
        `/conversation/${ticketId}/conversation-message`
      );
      setMessages(conversationMessageResponse.data.conversationMessages);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card className="mt-3">
      {!messages ? (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: 300 }}
        >
          <Spinner animation="border" role="status"></Spinner>
          <span className="mt-2">Loading...</span>
        </Flex>
      ) : (
        <>
          <Card.Header className="bg-light d-flex justify-content-between">
            <h5 className="mb-0">
              <FontAwesomeIcon icon="envelope" className="me-2" />
              {conversation && conversation.subject}
            </h5>
          </Card.Header>
          <Card.Body>
            {messages.map((message, index) => (
              <Mail key={index} message={message} index={index} />
            ))}
          </Card.Body>
        </>
      )}

      {showForm && (
        <Compose
          setShowForm={setShowForm}
          title="Reply"
          bodyClassName="shadow-none"
          conversation={conversation}
          fetchConverSationData={fetchData}
        />
      )}
      {!showForm && (
        <Card.Footer className="bg-light">
          <IconButton
            size="sm"
            variant="falcon-default"
            icon="reply"
            onClick={() => setShowForm(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">Reply</span>
          </IconButton>
          <IconButton
            size="sm"
            variant="falcon-default"
            icon="location-arrow"
            className="mx-2"
            onClick={() => setShowForm(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">Forward</span>
          </IconButton>
          <IconButton
            size="sm"
            variant="falcon-default"
            icon="file-alt"
            onClick={() => setShowForm(true)}
          >
            <span className="d-none d-sm-inline-block ms-1">Add Note</span>
          </IconButton>
        </Card.Footer>
      )}
    </Card>
  );
};

export default Ticket;
