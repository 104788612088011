import React, { useEffect, useState }from 'react';
import { useParams } from 'react-router-dom';
import { useForm  } from 'react-hook-form';
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faCircle } from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import IconAlert from 'components/common/IconAlert';
import axiosClient from 'config/axios';
import { useNavigate } from 'react-router-dom';
import ImageUpload from './ImageUpload'; 

// FormTitle Component
const FormTitle = ({ title }) => {
  return (
    <Flex className="mb-4">
      <span className="fa-stack me-2 ms-n1">
        <FontAwesomeIcon icon={faCircle} className="fa-stack-2x text-300" />
        <FontAwesomeIcon icon={faExchangeAlt} className="fa-inverse fa-stack-1x text-primary" />
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">{title}</h5>
      </div>
    </Flex>
  );
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

// RefundForm Component
const RefundForm = ({ conversation, onSuccess, onError }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleFileChange = (files) => {
        setUploadedFiles(files);
    };


    const onSubmit = async (data) => {
      try {
          // Create a FormData object to append the files and form data
          const formData = new FormData();
  
          // Append uploaded files to formData
          uploadedFiles.forEach(file => {
              formData.append('images', file);
          });
  
          // Append other form data to formData
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                formData.append(key, data[key]);
            }
        }

        
          // Perform the POST request to your server
          const response = await axiosClient.post('/request/refund-form', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
  
          // Handle different response statuses
          if (response.status === 201) {
              onSuccess(); // Call onSuccess callback if submission is successful
          } else {
              // Handle other statuses as needed
              console.log('Response status:', response.status);
          }
      } catch (error) {
          if (error.response) {
              // Handle specific errors from the server response
              onError(`Server responded with an error: ${error.response.data.message}`);
              console.error('Error response:', error.response);
          } else if (error.request) {
              // The request was made but no response was received
              onError("A network error has occurred, please try again later");
              console.error('Error request:', error.request);
          } else {
              // Something else happened while setting up the request
              onError("An error occurred, please try again later");
              console.error('Error:', error.message);
          }
      }
  };
  
  
 // Or send data to API
  const selectedReason = watch("reasonForRefund");
  const requestType = watch("requestType");

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* Refund or Exchange Selection */}
      <Form.Group className="mb-3">
        <Form.Label>Request Type</Form.Label>
        <Form.Select {...register('requestType', { required: "Request type is required" })}>
          <option value="">Select type</option>
          <option value="refund">Refund</option>
          <option value="exchange">Exchange</option>
        </Form.Select>
        {errors.requestType && <p className="text-danger">{errors.requestType.message}</p>}
      </Form.Group>

      {/* Reason for Refund/Exchange */}
      <Form.Group className="mb-3">
        <Form.Label>Reason for Refund/Exchange</Form.Label>
        <Form.Select {...register('reasonForRefund', { required: "Reason for refund/exchange is required" })}>
          <option value="">Select reason</option>
          <option value="Item damaged or defective">Item damaged or defective</option>
          <option value="Incorrect item received">Incorrect item received</option>
          <option value="Item did not meet expectations">Item did not meet expectations</option>
          <option value="size">Size/fit issues</option>
          <option value="other">Other</option>
        </Form.Select>
        {errors.reasonForRefund && <p className="text-danger">{errors.reasonForRefund.message}</p>}
      </Form.Group>

      {/* Conditional Text Area for 'Other' reason */}
      {selectedReason === 'other' && (
        <Form.Group className="mb-3">
          <Form.Label>Please specify your reason</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Specify your reason here" {...register('otherReason', { required: "Please specify your reason" })} />
          {errors.otherReason && <p className="text-danger">{errors.otherReason.message}</p>}
        </Form.Group>
      )}

      {/* Full Name */}
      <Form.Group className="mb-3">
        <Form.Label>Full Name</Form.Label>
        <Form.Control type="text" placeholder="Your Full Name" {...register('fullName', { required: "Full name is required" })} />
        {errors.fullName && <p className="text-danger">{errors.fullName.message}</p>}
      </Form.Group>

      {/* Email Address */}
      <Form.Group className="mb-3">
        <Form.Label>Email Address</Form.Label>
        <Form.Control 
          type="email" 
          placeholder="name@example.com" 
          {...register('email', { 
            required: "Email address is required",
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
              message: "Invalid email address"
            }
          })} 
        />
        {errors.email && <p className="text-danger">{errors.email.message}</p>}
      </Form.Group>


      {/* Order Number */}
      <Form.Group className="mb-3">
        <Form.Label>Order Number</Form.Label>
        <Form.Control type="text" placeholder="Order Number" {...register('orderNumber', { required: "Order number is required" })} />
        {errors.orderNumber && <p className="text-danger">{errors.orderNumber.message}</p>}
      </Form.Group>

      {/* Upload Image */}
             {/* ImageUpload Section */}
          <Form.Group className="mb-3">
                <Form.Label>Upload Images (if applicable)</Form.Label>
                <ImageUpload onFilesChange={handleFileChange} />
            </Form.Group>


         {/* Additional Details with specific instructions for Exchange */}
         <Form.Group className="mb-3" controlId="description">
                <Form.Label>Additional Details {requestType === 'exchange' && '(Mandatory for Exchange)'}</Form.Label>
                <Form.Control 
                    as="textarea" 
                    rows={3} 
                    placeholder={requestType === 'exchange' ? 
                        "Please provide details about the item you wish to exchange, including what product you would like instead, size, color, etc." : 
                        "Provide any additional information here"} 
                    {...register('additionalDetails', {
                        required: requestType === 'exchange' ? "Please provide details for the exchange" : false
                    })} 
                />
                {errors.additionalDetails && <p className="text-danger">{errors.additionalDetails.message}</p>}
            </Form.Group>

        
           {/* Hidden input for emailAccountId */}
        {conversation && conversation.conversation && (
        <input
          type="hidden"
          {...register('conversationId')}
          value={conversation.conversation.id}
        />
      )}

     {/* Hidden input for emailAccountId */}
        {conversation && conversation.conversation && (
        <input
          type="hidden"
          {...register('emailFromConvo')}
          value={conversation.conversation.email}
        />
      )}

      <Button variant="primary" type="submit">
        Submit Request
      </Button>
    </Form>
  );
};

// RefundRequestForm Component
const RefundRequestForm = () => {
    const { conversationId } = useParams();
    const [conversation, setConversation] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(null);
  
    const navigate = useNavigate()

    useEffect(() => {
        const fetchConversation = async () => {
          try {
            // Make an API call to fetch conversation data
            const response = await axiosClient.get(`/conversation/jwt/${conversationId}`);
      
            // Check if the response status is 200 (OK)
            if (response.status === 200) {
              const conversationData = response.data;
              // Set the conversation data in the state
              setConversation(conversationData);
            } else {
              navigate('/errors/404');
            }
          } catch (error) {
            // Handle network error or error response
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error(`Error fetching conversation: Received status code ${error.response.status}`);
              navigate('/errors/404');
            } else if (error.request) {
              // The request was made but no response was received
              console.error('Error fetching conversation: No response received', error.request);
              navigate('/errors/404');
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error fetching conversation:', error.message);
              navigate('/errors/404');
            }
          }
        };
      
        fetchConversation();
      }, [conversationId]);

        // Function to handle successful form submission
        const handleSuccess = () => {
            setFormSubmitted(true);
        };

        // Function to handle form submission error
        const handleError = (error) => {
            setFormError(error);
        };

        return (
            <>
            <Row className="my-5 g-4 justify-content-center">
              <Col xs={12} md={8} lg={6}>
                <FormTitle title="Refund and Exchange" />
                <Card>
                  <Card.Header>
                    {formError && <IconAlert variant='danger'>{formError}</IconAlert>}
                    {!formError && !formSubmitted && 
                      <IconAlert variant={'info'}>
                        <p className="mb-0">
                          {'Please fill out the form below to request a refund or exchange. Provide as much detail as possible to help us process your request efficiently.'}
                        </p>
                      </IconAlert>
                    }
                  </Card.Header>
                  <Card.Body>
                    {!formSubmitted && !formError ? 
                      <RefundForm conversation={conversation} onSuccess={handleSuccess} onError={handleError} />
                      : null
                    }
                    {formSubmitted && <IconAlert variant='success'>Success! We have received your refund request and will get back to you shortly.</IconAlert>}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
          
          );
        };
export default RefundRequestForm;

