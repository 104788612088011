import BasicECharts from 'components/common/BasicEChart';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  ToolboxComponent,
  TitleComponent
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
import { days, daysShot } from 'data/common';
import classNames from 'classnames';
import { getTotalNumber } from 'helpers/utils';
import Percentage from '../../Percentage';

echarts.use([
  GridComponent,
  ToolboxComponent,
  TitleComponent,
  LineChart,
  CanvasRenderer
]);


const getOptions = (data, styles) => ({
  tooltip: {
    trigger: 'axis',
    formatter: '{b0} : {c0}'
  },
  xAxis: {
    data: days
  },
  series: [
    {
      type: 'line',
      data: daysShot.map(day => ({
        value: data ? data[day] || 0 : 0,
        name: day
      })),
      color: styles.chartColor,
      smooth: true,
      lineStyle: {
        width: 2
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color:
              styles.chartColor === '#2c7be5'
                  ? 'rgba(44, 123, 229, .25)'
                  : styles.chartColor === '#00d27a'
                  ? 'rgba(0, 210, 122, .25)'
                  : styles.chartColor === '#27bcfd'
                  ? 'rgba(39, 188, 253, .25)'
                  : 'rgba(245, 128, 62, .25)'
            },
            {
              offset: 1,
              color:
              styles.chartColor === '#2c7be5'
                  ? 'rgba(44, 123, 229, 0)'
                  : styles.chartColor === '#00d27a'
                  ? 'rgba(0, 210, 122, 0)'
                  : styles.chartColor === '#27bcfd'
                  ? 'rgba(39, 188, 253, 0)'
                  : 'rgba(245, 128, 62, 0)'
            }
          ]
        }
      }
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '0',
    left: '0px'
  }
});


const borderStyle = index => {
  var border = '';
  if (index) {
    border = 'border-start-0 border-xl-start border-top border-xl-top-0';
  }
  return border;
};


const TicketStatusItem = ({ styles, singleData, index }) => {
  return (
    
    <Col
      md={12}
      xl={3}
      className={classNames(styles.className, borderStyle(index))}
    >
      <Row className="g-0">
        <Col xs={5}>
          <img src={styles.img} alt="" width="39" className="mt-1" />
          <h2 className="mt-2 mb-1 text-700 fw-normal">
            {getTotalNumber(singleData?.chartData)}
            <Percentage percentage={singleData?.percentage | 0} noBackground={true} className={'fs--1'}/>
          </h2>
          <h6 className="mb-0">{styles.title}</h6>
        </Col>
        <Col xs={7} className="d-flex align-items-center px-0">
          <BasicECharts
            echarts={echarts}
            options={getOptions(singleData?.chartData, styles)}
            className="w-100 h-50"
          />
        </Col>
      </Row>
    </Col>
  );
};


export default TicketStatusItem
