import React, { useContext, useEffect, useState } from 'react';

import { Accordion, Form } from 'react-bootstrap';
import WizardInput from './WizardInput';
import AdditionalInformationField from './AdditionalInformationField';
import FalconReactRange from 'components/doc-components/FalconReactRange';
import { AuthWizardContext } from 'context/Context';
import { FINANCIAL_SERVICESA } from 'data/common';
import FrequentlyAskedQuestionsField from './FrequentlyAskedQuestionsField';
import { useForm } from 'react-hook-form';

const FinancialServicesBusinessWizard = ({
  errors,
  setValue,
  bussinessType
}) => {
  const { register, getValues } = useForm();
  const { emailAccount } = useContext(AuthWizardContext);
  var defaulConfig = {};
  if (
    emailAccount.configuration &&
    emailAccount.configuration.gptConfig.configuration.about ===
      FINANCIAL_SERVICESA
  ) {
    defaulConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
  }

  const [activeKey, setActiveKey] = useState('0');
  const [bussinessConfig, setBussinessConfig] = useState({});
  const [rangeValue, setRangeValue] = useState([0]);
  const [configData, setConfigData] = useState(defaulConfig);

  const getBussinessWithCurrentFields = () => {
    const businessConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
    const config = {};
    const formFields = Object.keys(getValues());

    // Remove old config field
    formFields.map(field => {
      const parrentKey = field.split('->')[0];
      const childKey = field.split('->')[1];
      if (!config[parrentKey]) config[parrentKey] = {};
      if (businessConfig[parrentKey] && businessConfig[parrentKey][childKey]) {
        config[parrentKey][childKey] = businessConfig[parrentKey][childKey];
      }
    });
    return config;
  };

  const setBussinessConfigData = (infoType, key, data) => {
    const updatedData = bussinessConfig;
    if (!updatedData[infoType]) {
      updatedData[infoType] = {};
    }
    updatedData[infoType][key] = data;

    setBussinessConfig(pre => ({
      ...pre,
      ...updatedData
    }));
  };

  useEffect(() => {
    console.log(bussinessConfig);
    setValue('bussinessConfig', bussinessConfig);
  }, [bussinessConfig]);

  useEffect(() => {
    if (emailAccount.configuration) {
      const config = getBussinessWithCurrentFields();
      if (config['Investment Strategies']) {
        const value = [];
        value.push(
          config['Investment Strategies']['Average Portfolio Size Managed'] || 0
        );
        setRangeValue(value);
      }
      setConfigData(config);
      setBussinessConfig(config);
    }
  }, [emailAccount]);

  useEffect(() => {
    if (emailAccount.configuration) {
      if (
        emailAccount.configuration.gptConfig.configuration.about !==
        FINANCIAL_SERVICESA
      ) {
        setValue('bussinessConfig', {});
        setBussinessConfig({});
        setConfigData({});
        setRangeValue([0]);
      } else {
        setBussinessConfig(getBussinessWithCurrentFields());
      }
    }
  }, [bussinessType]);

  return (
    configData && (
      <Accordion
        activeKey={activeKey}
        onSelect={eventKey => setActiveKey(eventKey)}
      >
        <Accordion.Item eventKey="1">
          <Accordion.Header>Services Offered</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Types of Financial Services</Form.Label>
              {[
                'Wealth Management',
                'Investment Advisory',
                'Tax Planning',
                'Retirement Planning',
                'Estate Planning',
                'Other'
              ].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Services Offered']
                      ? bussinessConfig['Services Offered'][
                          'Types of Financial Services'
                        ] === label
                      : null
                  }
                  name="Types of Financial Services"
                  {...register('Services Offered->Types of Financial Services')}
                  onChange={() =>
                    setBussinessConfigData(
                      'Services Offered',
                      'Types of Financial Services',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <WizardInput
              type="textarea"
              label="Specialized Services"
              defaultValue={
                configData['Services Offered']
                  ? configData['Services Offered']['Specialized Services']
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Services Offered->Specialized Services'),
                onChange: e =>
                  setBussinessConfigData(
                    'Services Offered',
                    'Specialized Services',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Client Interaction</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="select"
              label="Preferred Method of Communication"
              defaultValue={
                configData['Client Interaction']
                  ? configData['Client Interaction'][
                      'Preferred Method of Communication'
                    ]
                  : null
              }
              options={['Email', 'Phone', 'In-Person', 'Video Call', 'Other']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Client Interaction->Preferred Method of Communication'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Client Interaction',
                    'Preferred Method of Communication',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="select"
              label="Average Response Time to Client Inquiries"
              defaultValue={
                configData['Client Interaction']
                  ? configData['Client Interaction'][
                      'Average Response Time to Client Inquiries'
                    ]
                  : null
              }
              options={[
                'Within an hour',
                'Within 24 hours',
                '2-3 days',
                'Other'
              ]}
              errors={errors}
              formControlProps={{
                ...register(
                  'Client Interaction->Average Response Time to Client Inquiries'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Client Interaction',
                    'Average Response Time to Client Inquiries',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Legal & Compliance</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Financial Services License Number"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Financial Services License Number'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register(
                  'Legal & Compliance->Financial Services License Number'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Financial Services License Number',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="text"
              label="Regulatory Bodies Affiliation"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Regulatory Bodies Affiliation'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register(
                  'Legal & Compliance->Regulatory Bodies Affiliation'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Regulatory Bodies Affiliation',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Insurance Coverage Details"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Insurance Coverage Details'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Legal & Compliance->Insurance Coverage Details'),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Insurance Coverage Details',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Investment Strategies</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Types of Investment Strategies Offered</Form.Label>

              {['Growth', 'Value', 'Income', 'Balanced', 'other'].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Investment Strategies']
                      ? bussinessConfig['Investment Strategies'][
                          'Types of Investment Strategies Offered'
                        ] === label
                      : null
                  }
                  name="Types of Investment Strategies Offered"
                  {...register(
                    'Investment Strategies->Types of Investment Strategies Offered'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Investment Strategies',
                      'Types of Investment Strategies Offered',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Average Portfolio Size Managed</Form.Label>
              <FalconReactRange
                values={rangeValue}
                variant="primary"
                {...register(
                  'Investment Strategies->Average Portfolio Size Managed'
                )}
                onChange={val => {
                  setRangeValue(val);
                  setBussinessConfigData(
                    'Investment Strategies',
                    'Average Portfolio Size Managed',
                    val[0]
                  );
                }}
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Online Resources</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Business Website Link"
              defaultValue={
                configData['Online Resources']
                  ? configData['Online Resources']['Business Website Link']
                  : null
              }
              errors={errors}
              formControlProps={{
                className: 'input-spin-none',
                ...register('Online Resources->Business Website Link', {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }),
                onChange: e =>
                  setBussinessConfigData(
                    'Online Resources',
                    'Business Website Link',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="text"
              label="Client Portal Access Link (if applicable)"
              defaultValue={
                configData['Online Resources']
                  ? configData['Online Resources']['Client Portal Access Link']
                  : null
              }
              errors={errors}
              formControlProps={{
                className: 'input-spin-none',
                ...register('Online Resources->Client Portal Access Link', {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }),
                onChange: e =>
                  setBussinessConfigData(
                    'Online Resources',
                    'Client Portal Access Link',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Online Financial Tools & Calculators"
              defaultValue={
                configData['Online Resources']
                  ? configData['Online Resources'][
                      'Online Financial Tools & Calculators'
                    ]
                  : null
              }
              placeholder="Brief details or links to tools like mortgage calculators, retirement planners, etc.."
              errors={errors}
              formControlProps={{
                ...register(
                  'Online Resources->Online Financial Tools & Calculators'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Online Resources',
                    'Online Financial Tools & Calculators',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Team & Advisors</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Number of Financial Advisors</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Team & Advisors']
                    ? configData['Team & Advisors'][
                        'Number of Financial Advisors'
                      ]
                    : null
                }
                {...register('Team & Advisors->Number of Financial Advisors')}
                onChange={e =>
                  setBussinessConfigData(
                    'Team & Advisors',
                    'Number of Financial Advisors',
                    e.target.value
                  )
                }
              />
            </Form.Group>

            <WizardInput
              type="textarea"
              label="Top Performing Advisors"
              defaultValue={
                configData['Team & Advisors']
                  ? configData['Team & Advisors']['Top Performing Advisors']
                  : null
              }
              placeholder="Brief details or names of top advisors"
              errors={errors}
              formControlProps={{
                ...register('Team & Advisors->Top Performing Advisors'),
                onChange: e =>
                  setBussinessConfigData(
                    'Team & Advisors',
                    'Top Performing Advisors',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Training & Development Programs for Advisors"
              defaultValue={
                configData['Team & Advisors']
                  ? configData['Team & Advisors'][
                      'Training & Development Programs for Advisors'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register(
                  'Team & Advisors->Training & Development Programs for Advisors'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Team & Advisors',
                    'Training & Development Programs for Advisors',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Client Onboarding</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Client Risk Assessment Questionnaire Link"
              defaultValue={
                configData['Client Onboarding']
                  ? configData['Client Onboarding'][
                      'Client Risk Assessment Questionnaire Link'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                className: 'input-spin-none',
                ...register(
                  'Client Onboarding->Client Risk Assessment Questionnaire Link',
                  {
                    pattern: {
                      value: /^(ftp|http|https):\/\/[^ "]+$/i,
                      message: 'Website URL must be valid'
                    }
                  }
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Client Onboarding',
                    'Client Risk Assessment Questionnaire Link',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Account Setup Process"
              defaultValue={
                configData['Client Onboarding']
                  ? configData['Client Onboarding']['Account Setup Process']
                  : null
              }
              placeholder="Brief description of the steps involved in setting up a new client account"
              errors={errors}
              formControlProps={{
                ...register('Client Onboarding->Account Setup Process'),
                onChange: e =>
                  setBussinessConfigData(
                    'Client Onboarding',
                    'Account Setup Process',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>Fees & Charges</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="select"
              label="Fee Structure"
              defaultValue={
                configData['Fees & Charges']
                  ? configData['Fees & Charges']['Fee Structure']
                  : null
              }
              options={['Fee-Only', 'Fee-Based', 'Commission-Based', 'Other']}
              errors={errors}
              formControlProps={{
                ...register('Fees & Charges->Fee Structure'),
                onChange: e =>
                  setBussinessConfigData(
                    'Fees & Charges',
                    'Fee Structure',
                    e.target.value
                  )
              }}
            />

            <Form.Group>
              <Form.Label>
                Average Fee Percentage for Assets Under Management{' '}
              </Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Fees & Charges']
                    ? configData['Fees & Charges'][
                        'Average Fee Percentage for Assets Under Management'
                      ]
                    : null
                }
                {...register(
                  'Fees & Charges->Average Fee Percentage for Assets Under Management'
                )}
                onChange={e =>
                  setBussinessConfigData(
                    'Fees & Charges',
                    'Average Fee Percentage for Assets Under Management',
                    e.target.value
                  )
                }
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>Booking Appointments</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Link to Book Appointments"
              defaultValue={
                configData['Booking Appointments']
                  ? configData['Booking Appointments'][
                      'Link to Book Appointments'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Booking Appointments->Link to Book Appointments'),
                onChange: e =>
                  setBussinessConfigData(
                    'Booking Appointments',
                    'Link to Book Appointments',
                    e.target.value
                  )
              }}
            />

            <Form.Group>
              <Form.Label>Preferred Days for Appointments</Form.Label>

              {[
                'Weekdays',
                'Weekends',
                'Specific days like Monday',
                'Tuesday',
                'Other'
              ].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Booking Appointments']
                      ? bussinessConfig['Booking Appointments'][
                          'Preferred Days for Appointments'
                        ] === label
                      : null
                  }
                  name="Preferred Days for Appointments"
                  {...register(
                    'Booking Appointments->Preferred Days for Appointments'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Booking Appointments',
                      'Preferred Days for Appointments',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <Form.Group>
              <Form.Label>Average Duration of an Appointment</Form.Label>

              {['15 mins', '30 mins', '1 hour'].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Booking Appointments']
                      ? bussinessConfig['Booking Appointments'][
                          'Average Duration of an Appointment'
                        ] === label
                      : null
                  }
                  name="Average Duration of an Appointment"
                  {...register(
                    'Booking Appointments->Average Duration of an Appointment'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Booking Appointments',
                      'Average Duration of an Appointment',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="10">
          <Accordion.Header>Frequently Asked Questions</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <FrequentlyAskedQuestionsField
              confg={bussinessConfig}
              setConfig={setBussinessConfig}
              data={configData['Frequently Asked Questions']}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="11">
          <Accordion.Header>Additional Information</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <AdditionalInformationField
              confg={bussinessConfig}
              setConfig={setBussinessConfig}
              data={configData['Additional Information']}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  );
};

export default FinancialServicesBusinessWizard;
