import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { faSadTear, faSmile, faMeh } from '@fortawesome/free-regular-svg-icons';
const statusStyle = {
  'Open': 'success',
  'Closed': 'info',
  'Escalated': 'warning',
}

const TicketsPreviewHeader = ({ conversation }) => {
  const navigate = useNavigate();
  return (
    <Card>
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigate(-1)}
          variant="falcon-default"
          size="sm"
          icon="arrow-left"
        />
        <Flex>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="object-ungroup"
            transform="shrink-2"
            iconAlign="middle"
          >
            <span className="d-none d-md-inline-block ms-1">Merge</span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="check"
            transform="shrink-2"
            iconAlign="middle"
            className="mx-2"
          >
            <span className="d-none d-md-inline-block ms-1">close</span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="ban"
            transform="shrink-2"
            iconAlign="middle"
          >
            <span className="d-none d-md-inline-block ms-1">Ban visitor</span>
          </IconButton>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="trash-alt"
            transform="shrink-2"
            iconAlign="middle"
            className="ms-2 d-none d-sm-block"
          >
            <span className="d-none d-md-inline-block ms-1">Delete</span>
          </IconButton>
          <Dropdown
            align="end"
            className="btn-reveal-trigger d-inline-block ms-2"
          >
            <Dropdown.Toggle split variant="falcon-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-v" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item>View</Dropdown.Item>
                <Dropdown.Item>Export</Dropdown.Item>
                <Dropdown.Item className="d-sm-none">Delete</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="text-danger">Remove</Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Flex>
      </Card.Header>
      {conversation && (
        <Card.Body className='overflow-x-auto'>
         {
            conversation.status &&
          <Flex className="gap-1">
            <span className="fw-semi-bold mb-0 text-800">Status: </span>
            <div>
                <SoftBadge bg={statusStyle[conversation.status]} className="me-1" >
                {conversation.status}
                </SoftBadge>
            </div>
          </Flex>
          }
          {
            conversation.sentiment && 
            <Flex className="gap-1" alignItems="center">
            <span className="fw-semi-bold mb-0 text-800">Sentiment: </span>
            {conversation.sentiment === 'Positive' ? (
              <>
                <span className="mb-0 text-success">
                  {conversation.sentiment}
                </span>
                <FontAwesomeIcon
                  icon={faSmile}
                  size="lg"
                  className="text-success"
                />
              </>
            ) : conversation.sentiment === 'Negative' ? (
              <>
                <span className="mb-0 text-danger">
                  {conversation.sentiment}
                </span>
                <FontAwesomeIcon
                  icon={faSadTear}
                  size="lg"
                  className="text-danger"
                />
              </>
            ) : (
              <>
                <span className="mb-0 text-gray">{conversation.sentiment}</span>
                <FontAwesomeIcon icon={faMeh} size="lg" className="text-gray" />
              </>
            )}
          </Flex>
          }
          {
            conversation.classification &&

          <Flex className="gap-1" alignItems="center">
            <span className="fw-semi-bold mb-0 text-800">Classification: </span>
            <span className="mb-0 text-600 ms-2">
              {conversation.classification}
            </span>
          </Flex>
          }
          {
            conversation.keywords &&
          <Flex className="gap-1">
            <span className="fw-semi-bold mb-0 text-800">Keywords: </span>
            <div>
              {conversation.keywords.split(',').map(keyword => (
                <SoftBadge bg="dark text-800" className="me-1" key={keyword}>
                  {keyword}
                </SoftBadge>
              ))}
            </div>
          </Flex>
          }
          {
            conversation.isEscalated &&
            <div className='flex flex-row rounded-3 px-3 py-2 mt-3' style={{ background: "#ffff39" }}>
            <div className="fw-semi-bold mb-0 text-800 text-center" style={{ color: "black" }}>Escalation Reason</div>
            <div style={{ color: "black" }}>{conversation.escalationReason ? conversation.escalationReason : "No reason"}</div>
          </div>
          } 
        </Card.Body>
      )}
    </Card>
  );
};

export default TicketsPreviewHeader;
