import React from 'react';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { faSadTear, faSmile, faMeh } from '@fortawesome/free-regular-svg-icons';
import { formatDate } from 'helpers/utils';

const statusStyle = {
  'Open': 'success',
  'Closed': 'info',
  'Escalated': 'warning',
}



const PrioritySelect = ({ title, color, data, className, style }) => {
  return (
    <div
      style={style}
      className={classNames('d-flex align-items-center gap-2', className)}
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  );
};

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const AgentSelect = ({ agent, className, style }) => {
  return (
    <Form.Select
      style={style}
      className={className}
      size="sm"
      defaultValue={agent}
    >
      {['Select Agent', 'Anindya', 'Nowrin', 'Khalid'].map(item => (
        <option key={item}>{item}</option>
      ))}
    </Form.Select>
  );
};

AgentSelect.propTypes = {
  agent: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

export const columns = [
  {
    accessor: 'name',
    Header: 'Client',
    headerProps: { className: 'ps-2', style: { height: '46px' } },
    cellProps: {
      className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
    },
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          <h6 className="mb-0">
            <Link
              to="/ai-support/contact-details"
              className="stretched-link text-900"
            >
              {email}
            </Link>
          </h6>
        </Flex>
      );
    }
  },
  {
    accessor: 'subject',
    Header: 'Subject',
    headerProps: { style: { minWidth: '14.625rem' } },
    cellProps: {
      className: 'py-2 pe-4'
    },
    Cell: rowData => {
      const { subject } = rowData.row.original;
      return (
       <h6 className="fw-semi-bold mb-0 text-info">{subject || "-"}</h6>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    cellProps: {
      className: 'fs-0 pe-4'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <SoftBadge bg={statusStyle[status]} className="me-2">
          {status}
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'sentiment',
    Header: 'Sentiment',
    cellProps: {
      className: 'fs-0 pe-4'
    },
    Cell: rowData => {
      const { sentiment } = rowData.row.original;
      return (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>{sentiment}</Tooltip>
          }
        >
          <p className='text-center'>
            {
              sentiment === 'Positive'
                ? <FontAwesomeIcon icon={faSmile} size='lg' className='text-success'/>
                : sentiment === 'Negative'
                  ? <FontAwesomeIcon icon={faSadTear} size='lg' className='text-danger'/>
                  : <FontAwesomeIcon icon={faMeh} size='lg' className='text-gray'/>
            }
          </p>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'classification',
    Header: 'Classification',
    cellProps: {
      className: 'fs-0 pe-4'
    },
    Cell: rowData => {
      const { classification } = rowData.row.original;
      return <h6>{classification || "-"}</h6>;
    }
  },
  {
    accessor: 'lastUpdated',
    Header: 'Last Updated',
    cellProps: {
      className: 'fs-0 pe-4'
    },
    Cell: rowData => {
      const { lastUpdated, createdAt } = rowData.row.original;
      return <h6>{formatDate(lastUpdated) || formatDate(createdAt)}</h6>;
    }
  }
];

export const CardLayout = ({ data, isSelectedItem, toggleSelectedItem }) => {
  return (
    <div className="d-flex flex-column gap-3">
      {data.slice(0, 12).map((ticket, index) => (
        <div
          key={index}
          className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
        >
          <div className="d-flex align-items-start align-items-sm-center">
            <Form.Check
              type="checkbox"
              id="inboxBulkSelect"
              className="fs-0 form-check me-2 me-xxl-3 mb-0"
            >
              <Form.Check.Input
                type="checkbox"
                checked={isSelectedItem(ticket.id)}
                onChange={() => toggleSelectedItem(ticket.id)}
              />
            </Form.Check>
            <Link
              to="/ai-support/contact-details"
              className="d-none d-sm-block"
            >
              {ticket.avatar.img ? (
                <Avatar src={ticket.avatar.img} size="3xl" />
              ) : (
                <Avatar size="3xl" name={ticket.avatar.name} />
              )}
            </Link>
            <div className="ms-1 ms-sm-3">
              <p className="fw-semi-bold mb-3 mb-sm-2">
                <Link to="/ai-support/tickets-preview">{ticket.subject}</Link>
              </p>
              <Row className="align-items-center gx-0 gy-2">
                <Col xs="auto" className="me-2">
                  <h6 className="mb-0">
                    <Link
                      to="/ai-support/contact-details"
                      className="text-800 d-flex align-items-center gap-1"
                    >
                      <FontAwesomeIcon icon="user" transform="shrink-3 up-1" />
                      <span>{ticket.name}</span>
                    </Link>
                  </h6>
                </Col>
                <Col xs="auto" className="lh-1 me-3">
                  <SoftBadge bg={ticket.status.type}>
                    {ticket.status.content}
                  </SoftBadge>
                </Col>
                <Col xs="auto">
                  <h6 className="mb-0 text-500">{ticket.date}</h6>
                </Col>
              </Row>
            </div>
          </div>
          <div className="border-bottom mt-4 mb-x1"></div>
          <div className="d-flex justify-content-between ms-auto">
            <PrioritySelect
              title={ticket.priority.title}
              color={ticket.priority.color}
              data={ticket.priority.data}
              className="ms-md-4 ms-xl-0"
              style={{ width: '7.5rem' }}
            />
            <AgentSelect agent={ticket.agent} style={{ width: '9.375rem' }} />
          </div>
        </div>
      ))}
    </div>
  );
};

CardLayout.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func
};
