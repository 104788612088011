import React, { useContext, useEffect, useState } from 'react';

import { Accordion, Form } from 'react-bootstrap';
import WizardInput from './WizardInput';
import AdditionalInformationField from './AdditionalInformationField';
import FalconReactRange from 'components/doc-components/FalconReactRange';
import { REAL_ESTATE } from 'data/common';
import { AuthWizardContext } from 'context/Context';
import FrequentlyAskedQuestionsField from './FrequentlyAskedQuestionsField';
import { useForm } from 'react-hook-form';

const RealEstateBusinessWizard = ({ errors, setValue, bussinessType }) => {
  const { register, getValues } = useForm();
  const { emailAccount } = useContext(AuthWizardContext);

  var defaulConfig = {};
  if (
    emailAccount.configuration &&
    emailAccount.configuration.gptConfig.configuration.about === REAL_ESTATE
  ) {
    defaulConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
  }

  const [activeKey, setActiveKey] = useState('0');
  const [bussinessConfig, setBussinessConfig] = useState({});
  const [rangeValue, setRangeValue] = useState([0]);
  const [configData, setConfigData] = useState(defaulConfig);

  const getBussinessWithCurrentFields = () => {
    const businessConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
    const config = {};
    const formFields = Object.keys(getValues());

    // Remove old config field
    formFields.map(field => {
      const parrentKey = field.split('->')[0];
      const childKey = field.split('->')[1];
      if (!config[parrentKey]) config[parrentKey] = {};
      if (businessConfig[parrentKey] && businessConfig[parrentKey][childKey]) {
        config[parrentKey][childKey] = businessConfig[parrentKey][childKey];
      }
    });
    return config;
  };

  const setBussinessConfigData = (infoType, key, data) => {
    const updatedData = bussinessConfig;
    if (!updatedData[infoType]) {
      updatedData[infoType] = {};
    }
    updatedData[infoType][key] = data;

    setBussinessConfig(pre => ({
      ...pre,
      ...updatedData
    }));
  };

  useEffect(() => {
    console.log(bussinessConfig);
    setValue('bussinessConfig', bussinessConfig);
  }, [bussinessConfig]);

  useEffect(() => {
    if (emailAccount.configuration) {
      const config = getBussinessWithCurrentFields();
      if (config['Property Listings']) {
        const value = [];
        value.push(
          config['Property Listings']['Average Property Price Range'] || 0
        );
        setRangeValue(value);
      }
      setConfigData(config);
      setBussinessConfig(config);
    }
  }, [emailAccount]);

  useEffect(() => {
    if (emailAccount.configuration) {
      if (
        emailAccount.configuration.gptConfig.configuration.about !== REAL_ESTATE
      ) {
        setValue('bussinessConfig', {});
        setBussinessConfig({});
        setConfigData({});
        setRangeValue([0]);
      } else {
        setBussinessConfig(getBussinessWithCurrentFields());
      }
    }
  }, [bussinessType]);

  return (
    configData && (
      <Accordion
        activeKey={activeKey}
        onSelect={eventKey => setActiveKey(eventKey)}
      >
        <Accordion.Item eventKey="1">
          <Accordion.Header>Services Offered</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Types of Services</Form.Label>

              {[
                'Property Sales',
                'Rentals',
                'Property Management',
                'Appraisals',
                'Foreclosures',
                'Relocation Services',
                'Other'
              ].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Services Offered']
                      ? bussinessConfig['Services Offered']['Types of Services'] ===
                        label
                      : false
                  }
                  name="Types of Services"
                  {...register('Services Offered->Types of Services')}
                  onChange={() =>
                    setBussinessConfigData(
                      'Services Offered',
                      'Types of Services',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <WizardInput
              type="textarea"
              label="Specialized Services "
              defaultValue={
                configData['Services Offered']
                  ? configData['Services Offered']['Specialized Services']
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Services Offered->Specialized Services'),
                onChange: e =>
                  setBussinessConfigData(
                    'Services Offered',
                    'Specialized Services',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Property Listings</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Types of Properties Handled</Form.Label>

              {[
                'Residential',
                'Commercial',
                'Land',
                'Industrial',
                'Vacation Homes',
                'Multi-Family',
                'Other'
              ].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Property Listings']
                      ? bussinessConfig['Property Listings'][
                          'Types of Properties Handled'
                        ] === label
                      : null
                  }
                  name="Types of Properties Handled"
                  {...register(
                    'Property Listings->Types of Properties Handled'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Property Listings',
                      'Types of Properties Handled',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <Form.Group>
              <Form.Label>Average Number of Active Listings</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Property Listings']
                    ? configData['Property Listings'][
                        'Average Number of Active Listings'
                      ]
                    : null
                }
                {...register(
                  'Property Listings->Average Number of Active Listings'
                )}
                onChange={e =>
                  setBussinessConfigData(
                    'Property Listings',
                    'Average Number of Active Listings',
                    e.target.value
                  )
                }
              />
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Average Property Price Range</Form.Label>
              <FalconReactRange
                values={rangeValue}
                variant="primary"
                {...register('Property Listings->Average Property Price Range')}
                onChange={val => {
                  setRangeValue(val);
                  setBussinessConfigData(
                    'Property Listings',
                    'Average Property Price Range',
                    val[0]
                  );
                }}
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Client Interaction</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="select"
              label="Preferred Method of Communication"
              defaultValue={
                configData['Client Interaction']
                  ? configData['Client Interaction'][
                      'Preferred Method of Communication'
                    ]
                  : null
              }
              options={['Email', 'Phone', 'In-Person', 'Other']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Client Interaction->Preferred Method of Communication'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Client Interaction',
                    'Preferred Method of Communication',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="select"
              label="Average Response Time to Client Inquiries"
              defaultValue={
                configData['Client Interaction']
                  ? configData['Client Interaction'][
                      'Average Response Time to Client Inquiries'
                    ]
                  : null
              }
              options={[
                'Within an hour',
                'Within 24 hours',
                '2-3 days',
                'Other'
              ]}
              errors={errors}
              formControlProps={{
                ...register(
                  'Client Interaction->Average Response Time to Client Inquiries'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Client Interaction',
                    'Average Response Time to Client Inquiries',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Legal & Compliance</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Real Estate License Number"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Real Estate License Number'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Legal & Compliance->Real Estate License Number'),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Real Estate License Number',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="text"
              label="Association Memberships (if any)"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance']['Association Memberships']
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Legal & Compliance->Association Memberships'),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Association Memberships',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Insurance Coverage Details"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Insurance Coverage Details'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Legal & Compliance->Insurance Coverage Details'),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Insurance Coverage Details',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Marketing & Promotions</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Primary Marketing Channels</Form.Label>

              {['Online Listings', 'Social Media', 'Print Media', 'Other'].map(
                label => (
                  <Form.Check
                    key={label}
                    type="radio"
                    label={label}
                    checked={
                      bussinessConfig['Marketing & Promotions']
                        ? bussinessConfig['Marketing & Promotions'][
                            'Primary Marketing Channels'
                          ] === label
                        : null
                    }
                    name="Primary Marketing Channels"
                    {...register(
                      'Marketing & Promotions->Primary Marketing Channels'
                    )}
                    onChange={() =>
                      setBussinessConfigData(
                        'Marketing & Promotions',
                        'Primary Marketing Channels',
                        label
                      )
                    }
                  />
                )
              )}
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Online Resources</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Business Website Link"
              defaultValue={
                configData['Online Resources']
                  ? configData['Online Resources']['Business Website Link']
                  : null
              }
              errors={errors}
              formControlProps={{
                className: 'input-spin-none',
                ...register('Online Resources->Business Website Link', {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }),
                onChange: e =>
                  setBussinessConfigData(
                    'Online Resources',
                    'Business Website Link',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="text"
              label="Online Property Listings Link (if separate from the main website) "
              defaultValue={
                configData['Online Resources']
                  ? configData['Online Resources'][
                      'Online Property Listings Link'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                className: 'input-spin-none',
                ...register('Online Resources->Online Property Listings Link', {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }),
                onChange: e =>
                  setBussinessConfigData(
                    'Online Resources',
                    'Online Property Listings Link',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="text"
              label="Client Portal Access Link (if applicable)"
              defaultValue={
                configData['Online Resources']
                  ? configData['Online Resources']['Client Portal Access Link']
                  : null
              }
              errors={errors}
              formControlProps={{
                className: 'input-spin-none',
                ...register('Online Resources->Client Portal Access Link', {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }),
                onChange: e =>
                  setBussinessConfigData(
                    'Online Resources',
                    'Client Portal Access Link',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Team & Agents</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Number of Agents</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Team & Agents']
                    ? configData['Team & Agents']['Number of Agents']
                    : null
                }
                {...register('Team & Agents->Number of Agents')}
                onChange={e =>
                  setBussinessConfigData(
                    'Team & Agents',
                    'Number of Agents',
                    e.target.value
                  )
                }
              />
            </Form.Group>

            <WizardInput
              type="textarea"
              label="Top Performing Agents"
              defaultValue={
                configData['Team & Agents']
                  ? configData['Team & Agents']['Top Performing Agents']
                  : null
              }
              placeholder="Brief details or names of top agents"
              errors={errors}
              formControlProps={{
                ...register('Team & Agents->Top Performing Agents'),
                onChange: e =>
                  setBussinessConfigData(
                    'Team & Agents',
                    'Top Performing Agents',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Training & Development Programs for Agents"
              defaultValue={
                configData['Team & Agents']
                  ? configData['Team & Agents'][
                      'Training & Development Programs for Agents'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register(
                  'Team & Agents->Training & Development Programs for Agents'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Team & Agents',
                    'Training & Development Programs for Agents',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>
            Property Management(if applicable)
          </Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Number of Properties Managed</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Property Management']
                    ? configData['Property Management'][
                        'Number of Properties Managed'
                      ]
                    : null
                }
                {...register(
                  'Property Management->Number of Properties Managed'
                )}
                onChange={e =>
                  setBussinessConfigData(
                    'Property Management',
                    'Number of Properties Managed',
                    e.target.value
                  )
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Types of Management Services</Form.Label>

              {[
                'Maintenance',
                'Tenant Screening',
                'Rent Collection',
                'Other'
              ].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Property Management']
                      ? bussinessConfig['Property Management'][
                          'Types of Management Services'
                        ] === label
                      : null
                  }
                  name="Types of Management Services"
                  {...register(
                    'Property Management->Types of Management Services'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Property Management',
                      'Types of Management Services',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <Form.Group>
              <Form.Label>Average Property ManagementFee (%)</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Property Management']
                    ? configData['Property Management'][
                        'Average Property ManagementFee'
                      ]
                    : null
                }
                {...register(
                  'Property Management->Average Property ManagementFee'
                )}
                onChange={e =>
                  setBussinessConfigData(
                    'Property Management',
                    'Average Property ManagementFee',
                    e.target.value
                  )
                }
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>Booking Appointments</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <WizardInput
              type="text"
              label="Link to Book Appointments"
              defaultValue={
                configData['Booking Appointments']
                  ? configData['Booking Appointments'][
                      'Link to Book Appointments'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Booking Appointments->Link to Book Appointments'),
                onChange: e =>
                  setBussinessConfigData(
                    'Booking Appointments',
                    'Link to Book Appointments',
                    e.target.value
                  )
              }}
            />

            <Form.Group>
              <Form.Label>Preferred Days for Appointments</Form.Label>

              {[
                'Weekdays',
                'Weekends',
                'Specific days like Monday',
                'Tuesday',
                'Other'
              ].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Booking Appointments']
                      ? bussinessConfig['Booking Appointments'][
                          'Preferred Days for Appointments'
                        ] === label
                      : null
                  }
                  name="Preferred Days for Appointments"
                  {...register(
                    'Booking Appointments->Preferred Days for Appointments'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Booking Appointments',
                      'Preferred Days for Appointments',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <WizardInput
              type="select"
              label="Average Duration of an Appointment"
              defaultValue={
                configData['Booking Appointments']
                  ? configData['Booking Appointments'][
                      'Average Duration of an Appointment'
                    ]
                  : null
              }
              options={['15 mins', '30 mins', '1 hour', 'Other']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Booking Appointments->Average Duration of an Appointment'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Booking Appointments',
                    'Average Duration of an Appointment',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="10">
          <Accordion.Header>Frequently Asked Questions</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <FrequentlyAskedQuestionsField
              confg={bussinessConfig}
              setConfig={setBussinessConfig}
              data={configData['Frequently Asked Questions']}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="11">
          <Accordion.Header>Additional Information</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <AdditionalInformationField
              confg={bussinessConfig}
              setConfig={setBussinessConfig}
              data={configData['Additional Information']}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  );
};

export default RealEstateBusinessWizard;
