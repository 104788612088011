import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TicketsVolume from './tickets-volume/TicketsVolume';
import ConversationsClassification from './conversations-classification/ConversationsClassification';
import SentimentAnalysis from './sentiment-analysis/SentimentAnalysis';
import ConversationsList from '../conversations-list/ConversationsList';
import KeywordTrends from '../KeywordTrends';
import AverageResponseTime from 'components/dashboards/default/AverageResponseTime';
import AverageResolutionTime from 'components/dashboards/default/AverageResolutionTime ';
import TicketStatus from './ticket-status';
import TotalEmailVolume from 'components/dashboards/default/TotalEmailVolume';
import ReopenedTicket from 'components/dashboards/default/ReopenedTicket';

const AnalysisView = () => {
  return (
    <>
      <Row className="g-3">
        <Col md={3}>
          <TotalEmailVolume />
        </Col>
        <Col md={3}>
          <ReopenedTicket />
        </Col>
        <Col md={3}>
          <AverageResponseTime />
        </Col>
        <Col md={3}>
          <AverageResolutionTime />
        </Col>
        <Col xxl={4}>
          <TicketStatus />
        </Col>
        <Col xxl={8}>
          <TicketsVolume />
        </Col>
        <ConversationsClassification />
        <SentimentAnalysis />
        <Col xs={12}>
          <ConversationsList />
        </Col>
        <Col xs={12}>
          <KeywordTrends />
        </Col>
      </Row>
    </>
  );
};

export default AnalysisView;
