import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Sentiment from 'components/dashboards/default/Sentiment';
import Classification from 'components/dashboards/default/Classification';
import ConversationsList from '../conversations-list/ConversationsList';
import KeywordTrends from '../KeywordTrends';
import TotalEmailVolume from 'components/dashboards/default/TotalEmailVolume';
import AverageResponseTime from 'components/dashboards/default/AverageResponseTime';
import AverageResolutionTime from 'components/dashboards/default/AverageResolutionTime ';
import TicketStatus from './ticket-status';
import ReopenedTicket from 'components/dashboards/default/ReopenedTicket';


const GlanceView = () => {
  return (
    <>
      <Row className="g-3">
        <Col md={3}>
          <TotalEmailVolume/>
        </Col>
        <Col md={3}>
          <ReopenedTicket/>
        </Col>
        <Col md={3}>
          <AverageResponseTime />
        </Col>
        <Col md={3}>
          <AverageResolutionTime />
        </Col>

        <Col xxl={12}>
          <TicketStatus  />
        </Col>
        <Col md={6}>
          <Sentiment
            title={'Sentiment Analysis - Weekly Overview'}
          />
        </Col>
        <Col md={6}>
          <Classification
            title={'Classification - Weekly Overview'}
          />
        </Col>

        <Col xs={12}>
          <ConversationsList />
        </Col>
        <Col xs={12}>
          <KeywordTrends />
        </Col>
      </Row>
    </>
  );
};

export default GlanceView;
