import WizardInput from 'components/wizard/WizardInput';
import axiosClient from 'config/axios';
import {
  AGENT,
  CLOSED,
  ESCALATED,
  OPEN,
  REOPENED,
  SYSTEM,
  USER
} from 'data/common';
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const PropertiesFilteringForm = ({ conversation, fetchConversationData }) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();
  const [status, setStatus] = useState('');
  const [agent, setAgent] = useState('');

  const onSubmitData = async () => {
    try {
      
      var conversationStatus = status

      if(status === OPEN){
        conversationStatus = REOPENED
      }

      const jwtId = conversation.jwtId
      const data = {agent: agent}
      const results = await axiosClient.post(`/conversation/update-status/${jwtId}?status=${conversationStatus}`, data );

      if(results.status === 200){
        toast.success(results.data.message, { theme: 'colored' });

      } else {
        toast.warning(results.data.message, { theme: 'colored' });
      }
        fetchConversationData()
    } catch (error) {
      console.log(error);
      toast.error(`Failed to update the ticket status`, { theme: 'colored' });
    }
  };



  return (
    <Card>
      <Card.Header className="bg-light">
        <h6 className="mb-0">Manage ticket status</h6>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2">
            <WizardInput
              type="select"
              label="Status"
              name="status"
              errors={errors}
              options={[OPEN, CLOSED, ESCALATED]}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                ...register('status', {
                  required: 'Status field is required'
                }),
                onChange: e =>  setStatus(e.target.value)
              }}
            />
          </div>
          <div className="mb-2">
            <WizardInput
              type="select"
              label="Agent"
              name="updateBy"
              errors={errors}
              options={[USER, SYSTEM, AGENT]}
              formGroupProps={{
                className: 'mb-3'
              }}
              formControlProps={{
                ...register('updateBy', {
                  required: 'Agent field is required'
                }),
                onChange: e =>  setAgent(e.target.value)

              }}
            />
          </div>
        </Form>
      </Card.Body>
      <Card.Footer className="border-top border-200 py-x1">
        <Button
          varient="primary"
          className="w-100"
          onClick={handleSubmit(onSubmitData)}
        >
          Update
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default PropertiesFilteringForm;
