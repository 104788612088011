import IconButton from 'components/common/IconButton';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const GLANCE_MODE = 'Glance';
const ANALYSIS_MODE = 'Analysis';

function SwitchModeButton(props) {
  const navigate = useNavigate();

  const { mode, setMode } = props;
  const divStyle = {
    width: 'fit-content',
    padding: '3px'
  };

  const getStyle = data => {
    var activeStyle = {
      span: '',
      h6: '',
      svg: '#47576c'
    };
    if (data === mode) {
      activeStyle = {
        span: 'border rounded-pill bg-primary',
        h6: 'text-white',
        svg: '#eee'
      };
    }
    return activeStyle;
  };

  return (
    <div className="d-flex align-items-center">
      <IconButton
        onClick={() => navigate(-1)}
        variant="falcon-default"
        size="sm"
        icon="arrow-left"
        style={{height: "fit-content"}}
      />
      <span
        className="d-flex border rounded-pill bg-300 m-auto border-400"
        style={divStyle}
      >
        <>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-glance-button">
                Shows data for the week
              </Tooltip>
            }
          >
            <span
              className={`d-flex align-items-center gap-1 px-3 py-2 text-500 cursor-pointer ${
                getStyle(GLANCE_MODE).span
              }`}
              onClick={() => setMode(GLANCE_MODE)}
            >
              <h6 className={`m-auto ${getStyle(GLANCE_MODE).h6}`}>
                {GLANCE_MODE}
              </h6>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                fill={getStyle(GLANCE_MODE).svg}
                height="14px"
                viewBox="0 0 448 512"
              >
                <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" />
              </svg>
            </span>
          </OverlayTrigger>

          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-glance-button">
                Provides detailed information for in-depth analysis and
                reporting
              </Tooltip>
            }
          >
            <span
              className={`d-flex align-items-center  gap-1 px-3 py-2 text-500 cursor-pointer ${
                getStyle(ANALYSIS_MODE).span
              }`}
              onClick={() => setMode(ANALYSIS_MODE)}
            >
              <h6 className={`m-auto ${getStyle(ANALYSIS_MODE).h6}`}>
                {ANALYSIS_MODE}
              </h6>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16px"
                fill={getStyle(ANALYSIS_MODE).svg}
                viewBox="0 0 512 512"
              >
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zm-312 8v64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80-96V280c0 13.3 10.7 24 24 24s24-10.7 24-24V120c0-13.3-10.7-24-24-24s-24 10.7-24 24zm80 64v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
              </svg>
            </span>
          </OverlayTrigger>
        </>
      </span>
    </div>
  );
}

export default SwitchModeButton;
