import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import Background from 'components/common/Background';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import { useParams } from 'react-router-dom';
import axiosClient from 'config/axios';
import Percentage from '../ai-support/Percentage';

const ReopenedTicket = () => {
  const { emailAccountId } = useParams();
  const [data, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [emailAccountId]);

  const fetchData = async () => {
    try {
      const params = { emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/reopened-ticket`, {
        params
      });
      setDate(response.data);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };
  return isLoading ? (
    <Placeholder as={Card.Text} animation="glow">
      <div className="w-100 placeholder rounded" style={{ height: 150 }}></div>
    </Placeholder>
  ) : (
    <Card className="h-md-100">
      <Background image={bg3} />
      <Card.Header className="pb-0 d-flex gap-2">
        <h6 className="mb-0 mt-2">Total reopened ticket</h6>
        {data && <Percentage percentage={data.percentage} />}
      </Card.Header>

      <Card.Body as={Flex}>
        {!hasError && data ? (
          <div className="d-flex gap-2 mt-auto me-auto">
            <h2 className="mb-1 fw-normal lh-1 text-700">
              {data.currentNumberReopenedTicket}
            </h2>
          </div>
        ) : (
          <div className="m-auto py-4">No data</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ReopenedTicket;
