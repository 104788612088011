import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function Percentage({percentage, noBackground, className}) {
  const styles = percentage => {
    const result = {
      icon: '',
      color: ''
    };
    if (percentage === 0 || !percentage) {
      result.icon = '';
      result.bg = 'danger';
      result.color = 'text-danger';
    } else if (percentage <= 0) {
      result.icon = 'caret-down';
      result.bg = 'danger';
      result.color = 'text-danger';
    } else {
      result.icon = 'caret-up';
      result.bg = 'success';
      result.color = 'text-success';
    }
    return result;
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="13215">Totals & Difference: This Week - Last Week</Tooltip>
      }
    >
      <span style={{ zIndex: 1 }}>
        <SoftBadge
          pill
          bg={noBackground ? 'transparent' : styles(percentage).bg}
          className={classNames("h-fit me-2 fs--2 mt-auto", styles(percentage).color, className)}
        >
          <FontAwesomeIcon icon={styles(percentage).icon} className="me-1" />
          {percentage ? Math.abs(percentage?.toFixed(1)) : 0}%
        </SoftBadge>
      </span>
    </OverlayTrigger>
  );
}

export default Percentage;
