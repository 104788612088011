import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, getTotalNumber, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useContext } from 'react';
import AppContext from 'context/Context';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const DashboardPieChart = ({ chartData, color, chartRef }) => {
  const getOption = () => ({
    color: color,
    legend: {
      left: 'left',
      textStyle: {
        color: getColor('gray-600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: window.innerWidth < 530 ? '45%' : '60%',
        center: ['50%', '55%'],

        data: Object.keys(chartData).map(key => ({
          value: getTotalNumber(chartData[key]?.chartData) | 0,
          name: key
        })),
        label: {
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: rgbaColor(getColor('gray-600'), 0.5)
          }
        }
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });

  const { config } = useContext(AppContext);
  const { isDark } = config;
  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getOption(isDark, chartData)}
      className="h-100 w-100"
    />
  );
};

export default DashboardPieChart;
