import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor, getTotalNumber } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Placeholder } from 'react-bootstrap';

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import classNames from 'classnames';
import axiosClient from 'config/axios';
import { useParams } from 'react-router-dom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);
const getOption = data => ({
  legend: {
    show: false
  },
  series: [
    {
      type: 'pie',
      radius: '90%',
      center: ['50%', '50%'],
      label: {
        show: false
      },
      data: [
        {

          value: getTotalNumber(data['Positive']?.chartData),
          name: 'Positive',
          itemStyle: { color: getColor('primary') }
        },
        {
          value: getTotalNumber(data['Negative']?.chartData),
          name: 'Negative',
          itemStyle: { color: getColor('danger') }
        },
        {
          value: getTotalNumber(data['Neutral']?.chartData),
          name: 'Neutral',
          itemStyle: { color: getColor('gray-400') }
        }
      ]
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const FormCheck = ({ title, id, inputClass, checkBoxClass, handleLegend }) => {
  return (
    <Form.Check
      className={classNames(checkBoxClass, 'd-flex align-items-center mb-0')}
    >
      <Form.Check.Input
        onChange={event => handleLegend(event, title)}
        type="checkbox"
        id={id}
        defaultChecked
        className={classNames(
          'dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer',
          inputClass
        )}
      />
      <Form.Check.Label
        htmlFor={id}
        className="form-check-label lh-base mb-0 fs--4 text-500 fw-semi-bold font-base cursor-pointer"
      >
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
};

const Sentiment = ({ title }) => {
  const { emailAccountId } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  
  const chartRef = useRef(null);

  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: name
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [emailAccountId]);

  const fetchData = async () => {
    try {
      const params = { filterType: 'week', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/sentiment`, {
        params
      });
      setData(response.data.sentiment);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };

  return isLoading ? (
    <Placeholder as={Card.Text} animation="glow">
      <div className="w-100 placeholder rounded" style={{ height: 170 }}></div>
    </Placeholder>
  ) : (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex gap-2">
        <h6 className="mb-0 mt-2">{title ? title : 'Sentiment'}</h6>
      </Card.Header>
      <Card.Body className="py-0 pb-2 d-flex justify-content-between">
       {
        !hasError && data 
        ? <>
         <Col xs={4} className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Positive"
              id="positive"
              inputClass="primary"
              checkBoxClass="mt-n1 mt-md-0 me-md-3"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Positive']?.chartData)}
            </h6>
          </div>
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Negative"
              id="negative"
              inputClass="red"
              checkBoxClass="me-md-3"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Negative']?.chartData)}
            </h6>
          </div>
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Neutral"
              id="neutral"
              inputClass="gray-400"
              checkBoxClass="mt-n1 mt-md-0 me-md-0"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Neutral']?.chartData)}
            </h6>
          </div>
        </Col>
        <div className="ps-0">
          <ReactEChartsCore
            ref={chartRef}
            echarts={echarts}
            option={getOption(data)}
            style={{ width: '7.5rem', height: '7.5rem' }}
          />
        </div>
        </>
        : <div className='py-5 m-auto'>No data</div>
       }
      </Card.Body>
    </Card>
  );
};

export default Sentiment;
