import React, { forwardRef } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import * as echarts from 'echarts/core';
import { getColor, getLabel, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import { CLOSED, CLOSED_ESCALATION, OPEN, OPEN_ESCALATION } from 'data/common';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data, filter) => ({
  color: [
    '#00D27A',
    '#0dcaf0',
    '#F5803E',
    '#2c7be5',
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-900') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: function (params) {
      return `<div>${params.seriesName}</div>
      <div class="fs--1 d-flex flex-between-center">
      <div>
      <div class="dot me-1 fs--2 d-inline-block" style="background-color:${
        params.borderColor ? params.borderColor : params.color
      }"></div>
        <span>${params.name} : </span> 
        </div>
      <strong>${params.value}</strong>
      </div>`;
    }
  },
  legend: {
    show: false
  },
  xAxis: {
    data: getLabel(filter),
    splitLine: { show: false },
    splitArea: { show: false },
    axisLabel: {
      color: getColor('gray-600'),
      interval: 0,
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: {
      show: false
    }
  },
  yAxis: {
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLabel: {
      color: getColor('gray-600')
    }
  },
  series: [
    {
      name: 'Open Tickets',
      type: 'bar',
      stack: 'one',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: getLabel(filter).map(label => data[OPEN]?.chartData[label] || 0)
    },
    {
      name: 'Closed Tickets',
      type: 'bar',
      stack: 'two',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: getLabel(filter).map(label => data[CLOSED]?.chartData[label] || 0)
    },
    {
      name: 'Open Escalation Tickets',
      type: 'bar',
      stack: 'three',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: getLabel(filter).map(label => data[OPEN_ESCALATION]?.chartData[label] || 0)
    },
    {
      name: 'Closed Escalation Tickets',
      type: 'bar',
      stack: 'four',
      emphasis: {
        itemStyle: {
          shadowColor: rgbaColor(getColor('dark'), 0.3)
        }
      },
      data: getLabel(filter).map(label => data[CLOSED_ESCALATION]?.chartData[label] || 0)
    },
  ],
  itemStyle: {
    borderRadius: [3, 3, 0, 0]
  },

  barWidth: '15px',
  grid: {
    top: '10%',
    bottom: 0,
    left: 0,
    right: 0,
    containLabel: true
  }
});

const TicketsVolumeChart = forwardRef(({ data, filter }, ref) => {
  const numOfColumns = getLabel(filter).length
  var width = '100%'
  if(numOfColumns>10){
    width = numOfColumns * 100
  }
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(data, filter)}
      ref={ref}
      style={{ height: '27rem',width: width, minWidth: "45rem" }}
    />
  );
});

TicketsVolumeChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default TicketsVolumeChart;
