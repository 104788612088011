import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import Select from 'react-select';
import { ECOMMERCE, FINANCIAL_SERVICESA, MEDICAL, REAL_ESTATE } from 'data/common';
import ECommerceBusinessWizard from './ECommerceBusinessWizard';
import MedicalBusinessWizard from './MedicalBusinessWizard';
import FinancialServicesBusinessWizard from './FinancialServicesBusinessWizard';
import RealEstateBusinessWizard from './RealEstateBusinessWizard';
import { Controller } from 'react-hook-form';


const BussinessForm = ({ control, register, errors, setValue, watch }) => {
  const daysOfWeek = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
  ];
  const [operatingDays, setOperatingDays] = useState(null);
  const [bussinessType, setBussinessType] = useState(null);

  useEffect(() => {
    register('operatingDays', {
      validate: value => value !== null || 'At least one day must be selected'
    });
  }, [register]);

  return (
    <>
      <WizardInput
        type="select"
        label="Industry"
        name="industry"
        placeholder="Select your industry"
        errors={errors}
        options={[ECOMMERCE, MEDICAL, FINANCIAL_SERVICESA, REAL_ESTATE]}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('industry', {
            required: 'Industry field is required'
          }),
          onChange: e => setBussinessType(e.target.value)
        }}
      />

      <WizardInput
        label="Business Name*"
        name="businessName"
        placeholder="Enter your business name..."
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('businessName', {
            required: 'Business Name field is required'
          })
        }}
      />
      <WizardInput
        type="email"
        errors={errors}
        label="Business Email*"
        name="businessEmail"
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('businessEmail', {
            required: 'Business Email is required',
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            },
            validate: {
              notSameAsEmail: value =>
                value.toLowerCase() !== watch('email').toLowerCase() ||
                'Business email cannot be the same as the email'
            }
          })
        }}
      />

      <WizardInput
        type="number"
        label="Business Phone Number*"
        name="businessPhoneNumber"
        placeholder="Enter business phone number..."
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('businessPhoneNumber', {
            required: 'Business phone number field is required',
            pattern: {
              value: /^[0-9]+$/i,
              message: 'Phone number must be valid'
            }
          })
        }}
      />

      <label>Operating Days*</label>
      <div style={{ marginBottom: '20px' }}>
        <Controller
            name="operatingDays"
            control={control}
            rules={{ required: 'Operating days field is required' }}
            render={({ field }) => (
              <Select
              {...field}
              options={daysOfWeek}
              placeholder="Select..."
              isMulti
              classNamePrefix="react-select"
              value={operatingDays}
              onChange={selectedOptions => {
                const selectedValues = selectedOptions
                  ? selectedOptions.map(option => option.value)
                  : [];
                setOperatingDays(selectedOptions);
                setValue('operatingDays', selectedValues);
              }}
            />
            )}
          />
          {errors['operatingDays'] && (
            <p
              style={{
                color: '#e63757',
                fontFamily: 'Poppins',
                fontSize: 12,
                marginTop: 4
              }}
            >
              {errors['operatingDays'].message}
            </p>
          )}
        
      </div>

      {operatingDays && (
        <>
          <WizardInput
            type="time"
            label="Opening From"
            name="openingTime"
            placeholder="Enter opening time..."
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('openingTime', {
                required: 'Opening time is required'
              })
            }}
          />

          <WizardInput
            type="time"
            label="Closing"
            name="closingTime"
            placeholder="Enter closing time..."
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              ...register('closingTime', {
                required: 'Closing time is required'
              })
            }}
          />
        </>
      )}
      <WizardInput
        label="Website*"
        name="Website"
        placeholder="Enter your website URL..."
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('Website', {
            pattern: {
              value: /^(https):\/\/[^ "]+$/i,
              message: 'Website URL must be valid'
            },
            required: 'Website URL is needed'
          })
        }}
      />

      <WizardInput
        type="textarea"
        label="Address*"
        name="address"
        placeholder="Enter your business address..."
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('address', {
            required: 'Address field is required',
            validate: {
              isValidAddress: value =>
                value.split(' ').length > 4 || 'Address must be valid',
              containsNumber: value =>
                /\d/.test(value) || 'Address must contain a number'
            }
          })
        }}
      />
      {bussinessType === ECOMMERCE && (
        <ECommerceBusinessWizard
          register={register}
          errors={errors}
          setValue={setValue}
          bussinessType={bussinessType}
          watch={watch}
        />
      )}
      {bussinessType === MEDICAL && (
        <MedicalBusinessWizard
          register={register}
          errors={errors}
          setValue={setValue}
          bussinessType={bussinessType}
          watch={watch}
          />
      )}
      {bussinessType === FINANCIAL_SERVICESA && (
        <FinancialServicesBusinessWizard
          register={register}
          errors={errors}
          setValue={setValue}
          bussinessType={bussinessType}
          watch={watch}
          />
      )}
      {bussinessType === REAL_ESTATE && (
        <RealEstateBusinessWizard
          register={register}
          errors={errors}
          setValue={setValue}
          bussinessType={bussinessType}
          watch={watch}
        />
      )}
    </>
  );
};

BussinessForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default BussinessForm;
