import openTicket from 'assets/img/ai-support/open-tickets.png';
import closedTicket from 'assets/img/ai-support/due-tickets.png';
import openEscalation from 'assets/img/ai-support/unassigned.png';
import closedEscalation from 'assets/img/ai-support/hold-tickets.png';

import person1 from 'assets/img/team/1-thumb.png';
import person2 from 'assets/img/team/25-thumb.png';
import person3 from 'assets/img/team/14-thumb.png';
import person4 from 'assets/img/team/3-thumb.png';

export const statusStyle = {
  'Open': {
    color: 'success',
    img: openTicket,
    title: 'Open Tickets',
    chartColor: '#00d27a',
    className: 'px-x1 p-3'
  },
  'Closed': {
    color: 'info',
    img: closedTicket,
    title: 'Closed Tickets',
    chartColor: '#27bcfd',
    className: 'px-x1 p-3 border-top border-200'
  },
  'Open Escalation': {
    color: 'warning',
    img: openEscalation,
    title: 'Open Escalation',
    chartColor: '#f5803e',
    className: 'px-x1 p-3 border-top border-200'
  },
  'Closed Escalation': {
    color: 'primary',
    img: closedEscalation,
    title: 'Closed Escalation',
    chartColor: '#2c7be5',
    className: 'px-x1 p-3 border-top border-200'
  }
};


export const statusData = [
  {
    color: 'success',
    img: openTicket,
    percentage: 3.2,
    count: '05',
    title: 'Open Tickets',
    dataArray: [10, 12, 16, 14, 20, 25, 12],
    chartColor: '#00d27a',
    icon: 'caret-up',
    className: 'ps-x1 pe-x1 pb-3 '
    },
  {
    color: 'info',
    img: closedTicket,
    percentage: 2.3,
    count: '02',
    title: 'Closed Tickets',
    dataArray: [15, 10, 15, 10, 12, 10, 13],
    chartColor: '#27bcfd',
    icon: 'caret-down',
    className: 'ps-x1 pe-x1 py-3 border-y border-200'
  },
  {
    color: 'warning',
    img: openEscalation,
    percentage: 3.1,
    count: '03',
    title: 'Escalated Tickets',
    dataArray: [10, 15, 12, 11, 14, 12, 20],
    chartColor: '#f5803e',
    icon: 'caret-up',
    className: 'ps-x1 pe-x1 py-3 border-y border-200'
  },
  {
    color: 'primary',
    img: closedEscalation,
    percentage: 3.1,
    count: '03',
    title: 'Escalated Tickets',
    dataArray: [10, 15, 12, 11, 14, 12, 20],
    chartColor: '#f5803e',
    icon: 'caret-up',
    className: 'ps-x1 pe-x1 pt-3'
  }
];

export const unresolvedTickets = [
  [20, 18, 15, 20, 12, 15, 10],
  [30, 20, 20, 25, 20, 15, 10],
  [35, 32, 40, 50, 30, 25, 15],
  [15, 25, 20, 18, 10, 15, 25]
];

export const ticketsVolumeData = {
  WEEKS: {
    open: {
      total: 36,
      percentage: 2.3,
      data: [8, 4, 7, 2, 1, 9, 5],
    },
    closed: {
      total: 40,
      percentage: -1.5,
      data: [3, 10, 7, 6, 2, 4, 8],
    },
    escalated: {
      total: 40,
      percentage: 1.5,
      data: [9, 3, 6, 10, 1, 4, 7],
    },
  },
  DAYS_OF_MONTH: {
    open: {
      total: 131,
      percentage: 3.3,
      data: [7, 8, 2, 4, 5, 9, 3, 10, 1, 6, 6, 7, 8, 2, 1, 3, 9, 10, 5, 6, 8, 2, 1, 5, 9, 8, 6, 4, 2, 3],
    },
    closed: {
      total: 171,
      percentage: -0.5,
      data: [4, 6, 5, 3, 10, 9, 8, 3, 8, 7, 9, 10, 6, 2, 9, 8, 3, 2, 4, 7, 9, 2, 10, 7, 1, 8, 9, 4, 5, 6],
    },
    escalated: {
      total: 158,
      percentage: 1.7,
      data: [6, 1, 7, 2, 10, 8, 5, 4, 9, 8, 2, 4, 3, 6, 7, 5, 10, 9, 8, 3, 1, 10, 4, 9, 8, 3, 7, 6, 5, 2],
    },
  },

  YEARS: {
    open: {
      total: 4000,
      percentage: 1.3,
      data: [1500, 2500],
    },
    closed: {
      total: 5500,
      percentage: -0.7,
      data: [2000, 3500],
    },
    escalated: {
      total: 3220,
      percentage: 1.2,
      data: [1220, 2000],
    },
  },
}

export const conversationsPieChartData = [
  {
    value: 1048,
    name: 'Customer inquiry'
  },
  {
    value: 735,
    name: 'Customer feedback'
  },
  {
    value: 580,
    name: 'Customer complaint'
  },
  {
    value: 484,
    name: 'Other'
  }
];

export const sentimentPieChartData = [
  {
    value: 1200,
    name: 'Positive'
  },
  {
    value: 2000,
    name: 'Negative'
  },
  {
    value: 1500,
    name: 'Neutral'
  },
];
export const SentimentLineChartData = [
  {
    value: 1200,
    name: 'Positive'
  },
  {
    value: 2000,
    name: 'Negative'
  },
  {
    value: 1500,
    name: 'Neutral'
  },
];

export const todoList = [
  {
    id: 1,
    task: 'Sidenav text cutoff rendering issue',
    title: 'Problem with Falcon theme',
    completed: false
  },
  {
    id: 2,
    task: 'Notify when the WebPack release is ready',
    title: 'Falcon Bootstarp 5',
    completed: false
  },
  {
    id: 3,
    task: 'File Attachments',
    title:
      'Sending attachments automatically attaches them to the notification email that the client receives as well as making them accessible through.',
    completed: false
  },
  {
    id: 4,
    task: 'Billing a Client',
    title:
      "It's also possible to create a billable item for a client when you reply to a ticket. You can generate invoices or log time spent on a ticket.",
    completed: false
  },
  {
    id: 5,
    task: 'Sidenav text cutoff rendering issue',
    title: 'Problem with Falcon theme',
    completed: false
  },
  {
    id: 6,
    task: 'Notify when the WebPack release is ready',
    title: 'Falcon Bootstarp 5',
    completed: false
  },
  {
    id: 7,
    task: 'File Attachments',
    title:
      'Sending attachments automatically attaches them to the notification email that the client receives as well as making them accessible through.',
    completed: false
  }
];

const status = [
  { content: 'Open', type: 'success' },
  { content: 'Closed', type: 'secondary' },
  { content: 'In Progress', type: 'info' },
];

export const tickets = [
  {
    id: 1,
    name: 'Emma Watson',
    avatar: {
      name: 'Emma Watson',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Synapse Design #1125',
    status:  status[0],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 2,
    name: 'Luke',
    avatar: {
      name: 'Luke',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Change of refund my last buy | Order #125631',
    status: status[1],
    sentiment: 'Negative',
    classification: 'Feedback',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 3,
    name: 'Finley',
    avatar: {
      img: person1,
      size: 'xl'
    },
    subject: 'I need your help #2256',
    status: status[2],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 4,
    name: 'Peter Gill',
    avatar: {
      name: 'Peter Gill',
      size: 'xl',
      round: 'circle'
    },
    subject: 'I need your help #2256',
    status: status[0],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 5,
    name: 'Freya',
    avatar: {
      img: person2,
      size: 'xl'
    },
    subject: 'Contact Froms #3264',
    status: status[1],
    sentiment: 'Negative',
    classification: 'Feedback',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 6,
    name: 'Morrison',
    avatar: {
      name: 'Morrison',
      size: 'xl',
      round: 'circle'
    },
    subject: 'I need your help #2256',
    status: status[0],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 7,
    name: 'Morrison Banneker',
    avatar: {
      name: 'Morrison Banneker',
      size: 'xl',
      round: 'circle'
    },
    subject: 'I need your help #2256',
    status: status[1],
    sentiment: 'Negative',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 8,
    name: 'Aar Kay',
    avatar: {
      img: person3,
      size: 'xl'
    },
    subject: 'Regarding Falcon Theme #3262',
    status: status[0],
    sentiment: 'Negative',
    classification: 'Feedback',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 9,
    name: 'Fadil Badr',
    avatar: {
      name: 'Fadil Badr',
      size: 'xl',
      round: 'circle'
    },
    subject: 'i would like to buy theme #3261',
    status: status[1],
    sentiment: 'Positive',
    classification: 'Inquiry',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 10,
    name: 'Regina Kempt',
    avatar: {
      img: person4,
      size: 'xl'
    },
    subject: 'Theme info (icons) #3260',
    status: status[1],
    sentiment: 'Neutral',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 11,
    name: 'Caleb',
    avatar: {
      name: 'Caleb',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Phishing link #3259',
    status: status[0],
    sentiment: 'Neutral',
    classification: 'Inquiry',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 12,
    name: 'Fadil Badr',
    avatar: {
      name: 'Fadil Badr',
      size: 'xl',
      round: 'circle'
    },
    subject: 'i would like to buy theme #3261',
    status: status[1],
    sentiment: 'Neutral',
    classification: 'Feedback',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 13,
    name: 'Regina Kempt',
    avatar: {
      img: person4,
      size: 'xl'
    },
    subject: 'Theme info (icons) #3260',
    status: status[1],
    sentiment: 'Positive',
    classification: 'Inquiry',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 14,
    name: 'Caleb',
    avatar: {
      name: 'Caleb',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Phishing link #3259',
    status: status[0],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 15,
    name: 'Emma Watson',
    avatar: {
      name: 'Emma Watson',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Synapse Design #1125',
    status: status[0],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 16,
    name: 'Luke',
    avatar: {
      name: 'Luke',
      size: 'xl',
      round: 'circle'
    },
    subject: 'Change of refund my last buy | Order #125631',
    status: status[1],
    sentiment: 'Positive',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 17,
    name: 'Finley',
    avatar: {
      img: person1,
      size: 'xl'
    },
    subject: 'I need your help #2256',
    status: status[2],
    sentiment: 'Neutral',
    classification: 'Complaint',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 18,
    name: 'Peter Gill',
    avatar: {
      name: 'Peter Gill',
      size: 'xl',
      round: 'circle'
    },
    subject: 'I need your help #2256',
    status: status[0],
    sentiment: 'Positive',
    classification: 'Feedback',
    lastUpdated: '10:20 19/08/23'
  },
  {
    id: 19,
    name: 'Freya',
    avatar: {
      img: person2,
      size: 'xl'
    },
    subject: 'Contact Froms #3264',
    status: status[1],
    sentiment: 'Negative',
    classification: 'Inquiry',
    lastUpdated: '10:20 19/08/23'
  },
];
