import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Placeholder, Row, Spinner } from 'react-bootstrap';

import { MONTH, MONTHS } from 'data/common';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, getLabel, rgbaColor } from 'helpers/utils';
import axiosClient from 'config/axios';
import Flex from 'components/common/Flex';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data, filter) => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: '{b0} : {c0}',
    transitionDuration: 0
  },
  xAxis: {
    type: 'category',
    data: getLabel(filter),
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      type: 'line',
      data: getLabel(filter).map(day => ({
        value: data[day] || 0,
        name: day
      })),
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true }
});

const EmailVolume = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [data, setDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(MONTH);

  const handleFiltering = e => {
    setDate(null)
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
      setIsLoading(true);
      fetchData();
  }, [selectedOption]);

  const fetchData = async () => {
    try {
      const params = { filterType: selectedOption };
      const response = await axiosClient.get(`/conversation/email-volume`, {
        params
      });
      setDate(response.data);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };

  return (
    <Card className="h-md-100">
      <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
        <h6 className="mb-2 mb-md-0 py-md-2">Ticket Volume Over Time</h6>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <Form.Select
              value={selectedOption}
              onChange={handleFiltering}
              size="sm"
            >
              <option value={MONTH}>Month</option>
              {/* <option value={QUARTER}>Quarter</option> */}
              <option value={MONTHS}>Year</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Header>
      {isLoading ? (
        <Placeholder as={Card.Text} animation="glow">
          <div
            className="w-100 placeholder rounded"
            style={{ height: 335 }}
          ></div>
        </Placeholder>
      ) : (
        <Card.Body>
          {isLoading && (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ height: 390 }}
              className="position-absolute start-50 top-50 translate-middle"
            >
              <Spinner animation="border" role="status"></Spinner>
              <span className="mt-2">Loading...</span>
            </Flex>
          )}
          {
            !hasError && data 
            ?
            <ReactEChartsCore
              echarts={echarts}
              option={getOptions(data.chartData, selectedOption)}
              style={{ height: '18.4375rem' }}
            />
            : <div className="py-5 text-center">No data</div>
          }
        </Card.Body>
      )}
    </Card>
  );
};

EmailVolume.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired)
    .isRequired
};

export default EmailVolume;
