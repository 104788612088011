import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor, getTotalNumber } from 'helpers/utils';
import React, { useContext, useRef, useState } from 'react';
import { Card, Col, Placeholder } from 'react-bootstrap';

import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import FormCheck from './FormCheck';
import AppContext from 'context/Context';
import { useEffect } from 'react';
import axiosClient from 'config/axios';
import { useParams } from 'react-router-dom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOption = (isDark, data) => ({
  legend: {
    show: false
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '90%'],
      center: ['50%', '50%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 5,
        borderColor: getColor('gray-100'),
        borderWidth: 2
      },
      label: {
        show: false
      },
      data: [
        {
          value: getTotalNumber(data['Customer inquiry']?.chartData),
          name: 'Inquiry',
          itemStyle: { color: getColor('primary') }
        },
        {
          value: getTotalNumber(data['Customer feedback']?.chartData),
          name: 'Feedback',
          itemStyle: { color: getColor('info') }
        },
        {
          value: getTotalNumber(data['Customer complaint']?.chartData),
          name: 'Complaint',
          itemStyle: { color: getColor('danger') }
        },
        {
          value: getTotalNumber(data['Other']?.chartData),
          name: 'Other',
          itemStyle: { color: getColor('warning') }
        }
      ]
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const Classification = ({ title }) => {
  const { emailAccountId } = useParams();
  const [data, setDate] = useState(null);
  const { config } = useContext(AppContext);
  const { isDark } = config;
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  
  const chartRef = useRef(null);

  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: name
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [emailAccountId]);

  const fetchData = async () => {
    try {
      const params = { filterType: 'week', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/classification`, {
        params
      });
      setDate(response.data.classification);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };
  return isLoading ? (
    <Placeholder as={Card.Text} animation="glow">
      <div className="w-100 placeholder rounded" style={{ height: 170 }}></div>
    </Placeholder>
  ) : (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex gap-2">
        <h6 className="mb-0 mt-2">{title ? title : 'Classification'}</h6>
      </Card.Header>
      <Card.Body className="py-0 pb-2 d-flex justify-content-between">
        {
          !hasError && data 
          ? <>
          <Col xs={5} className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Inquiry"
              id="customerInquiry"
              inputClass="primary"
              checkBoxClass="mt-n1 mt-md-0 me-md-3"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Customer inquiry']?.chartData)}
            </h6>
          </div>
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Feedback"
              id="feedback"
              inputClass="info"
              checkBoxClass="me-md-3"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Customer feedback']?.chartData)}
            </h6>
          </div>
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Complaint"
              id="complaint"
              inputClass="red"
              checkBoxClass="me-md-3"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Customer complaint']?.chartData)}
            </h6>
          </div>
          <div className="d-flex justify-content-between">
            <FormCheck
              title="Other"
              id="other"
              inputClass="warning"
              checkBoxClass="mt-n1 mt-md-0 me-md-0"
              handleLegend={handleLegend}
            />
            <h6 className="fs--4 text-500 fw-semi-bold font-base my-auto">
              {getTotalNumber(data['Other']?.chartData)}
            </h6>
          </div>
        </Col>
        <div className="ps-0">
          <ReactEChartsCore
            ref={chartRef}
            echarts={echarts}
            option={getOption(isDark, data)}
            style={{ width: '7.5rem', height: '7.5rem' }}
          />
        </div></>
        : <div className='py-5 m-auto'>No data</div>
        }
        </Card.Body>
    </Card>
  );
};

export default Classification;
