import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Placeholder, Row, Spinner } from 'react-bootstrap';
import { months } from 'data/common';
import { CloudChart } from '../chart/CloudChart';
import axiosClient from 'config/axios';
import { useParams } from 'react-router-dom';
import Flex from 'components/common/Flex';

const KeywordTrends = () => {
  const { emailAccountId } = useParams();

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    new Date().getMonth() + 1
  );

  const handleFiltering = e => {
    setData([]);
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [selectedOption, emailAccountId]);

  const fetchData = async () => {
    try {
      const params = {
        month: selectedOption,
        emailAccountId: emailAccountId
      };
      const response = await axiosClient.get(`/conversation/keyword-trends`, {
        params: params
      });
      setData(response.data.keywordCounts);
      setIsFirstLoading(false);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsFirstLoading(false)
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };
  const windowWidth = useRef(window.innerWidth);
  return (
    <Card className="card h-100">
      <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
        <h6 className="mb-2 mb-md-0 py-md-2">Keyword Trends</h6>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <Form.Select
              value={selectedOption}
              onChange={handleFiltering}
              size="sm"
            >
              {months.map((item, idx) => (
                <option key={idx} value={idx + 1}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Card.Header>
      {isFirstLoading ? (
        <Placeholder as={Card.Text} animation="glow">
          <div
            className="w-100 placeholder rounded"
            style={{ height: windowWidth.current < 768 ? '15rem' : '40rem' }}
          ></div>
        </Placeholder>
      ) : (
        <Card.Body className="p-3 d-flex aligm-items-center" style={{ minHeight: '20rem' }}>
          {isLoading ? (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ height: windowWidth.current < 768 ? '15rem' : '40rem' }}
              className="position-absolute start-50 top-50 translate-middle"
            >
              <Spinner animation="border" role="status"></Spinner>
              <span className="mt-2">Loading...</span>
            </Flex>
          ) : !hasError && data.length ? (
            <CloudChart data={data} />
          ) : (
            <div className="py-5 m-auto ">No data</div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default KeywordTrends;
