import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { useNavigate } from 'react-router-dom';

const Success = ({ reset  }) => {
  const navigate = useNavigate()
  const { setStep, setEmail } = useContext(AuthWizardContext);

  const emptyData = () => {
    //clean up storage 
    localStorage.removeItem('formData');
    localStorage.removeItem('currentStep');
    setStep(1);
    setEmail({});
    reset();
    navigate(-1)

  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-0">Now you can access to your account</p>
          <Button color="primary" className="px-5 my-3" onClick={emptyData}>
            Back
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
