import React, { useEffect, useState } from 'react';
import TicketsPreviewHeader from './TicketsPreviewHeader';
import { useParams } from 'react-router-dom';
import axiosClient from 'config/axios';
import { Col, Row } from 'react-bootstrap';
import Ticket from './Ticket';
import PropertiesFilteringForm from './PropertiesFilteringForm';

const TicketsPreview = () => {
  const { ticketId } = useParams();
  const [conversation, setConversation] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const conversationResponse = await axiosClient.get(`/conversation/${ticketId}`);
      setConversation(conversationResponse.data.conversation);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Row className="g-3">
      <Col xxl={9} xl={8}>
        <TicketsPreviewHeader conversation={conversation} />
        <Ticket conversation={conversation}/>
      </Col>
      <Col xxl={3} xl={4}>
        <PropertiesFilteringForm conversation={conversation} fetchConversationData={fetchData}/>
      </Col>
    </Row>
  );
};

export default TicketsPreview;
