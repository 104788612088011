import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Flex from 'components/common/Flex';
import axiosClient from 'config/axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Email = ({ email, isLast }) => {
  const { name, total } = email;
  return (
    <Row
      className={classNames('position-relative align-items-center py-2', {
        'border-bottom border-200 ': !isLast
      })}
    >
      <Col xs={9} className="py-1">
        <Flex className="align-items-center">
          <div className="avatar avatar-xl me-3">
            <Avatar size="xl" name={name} className="me-2" />
          </div>
          <Flex>
            <Flex tag="h6" align="center" className="mb-0">
              <Link
                className="text-800 stretched-link"
                to={`/email/${email.id}`}
              >
                {name}
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col xs={3}>
        <h6 className="fs--1 fw-semi-bold text-end ml-5">{total}</h6>
      </Col>
    </Row>
  );
};

Email.propTypes = {
  email: PropTypes.shape({
    color: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    duration: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  isLast: PropTypes.bool
};

const EmailConfigurations = () => {
  const [data, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const params = { filterType: 'week' };
      const response = await axiosClient.get(`/email-account`, { params });
      setDate(response.data.emailAccounts);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };

  const handleClearStorage = () => {
    //Clear form data so user can start fresh
    localStorage.removeItem('formData');
    localStorage.removeItem('currentStep');
  };

  return isLoading ? (
    <Placeholder as={Card.Text} animation="glow">
      <div className="w-100 placeholder rounded" style={{ height: 380 }}></div>
    </Placeholder>
  ) : (
    <Card className="h-md-100">
      <Card.Header className="d-flex justify-content-between border-bottom border-200 py-3 py-md-2">
        <h6 className="mb-2 mb-md-0 py-md-2">Active Virtual Assistants</h6>
        <h6 className="mb-2 mb-md-0 py-md-2">Total Ticket Count</h6>
      </Card.Header>

      <Card.Body className="d-flex flex-column py-0">
        {!hasError && data ? (
          data.map((email, index) => (
            <Email
              email={email}
              isLast={index === data.length - 1}
              key={email.id}
            />
          ))
        ) : (
          <div className="m-auto">No data</div>
        )}
      </Card.Body>

      <FalconCardFooterLink title="Add email" to="/email/AddEmail" size="sm" onClick={handleClearStorage} />
    </Card>
  );
};

EmailConfigurations.propTypes = {
  data: PropTypes.arrayOf(Email.propTypes.email).isRequired
};

export default EmailConfigurations;
