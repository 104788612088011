import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import warningLight from 'assets/img/animated-icons/warning-light.json';

const AccountForm = ({ register, errors, isOauthSuccessful  }) => {
  return (
    <>

    {isOauthSuccessful === true && (
      <Alert variant="success">
      
        <Row className="justify-content-center align-items-center">
          <p className='text-center'>Your all set with this step, no changes needed or allowed</p>
          <Lottie animationData={checkPrimaryLight} loop={true} style={{height: '80px', width: '120px'}} />
        </Row>
      </Alert>
    )}
    
    {isOauthSuccessful === false && (
      <Alert variant="danger">
      
        <Row className="justify-content-center align-items-center">
          <p className='text-center'>An error occurred when trying to authenticate you with your proiver, please try again.</p>
          <Lottie animationData={warningLight} loop={true} style={{height: '80px', width: '120px'}} />
        </Row>
      </Alert>
    )}

    <WizardInput
        type="select"
        label="Email Type*"
        name="email type"
        placeholder="Select email type"
        errors={errors}
        options={['Customer Service']}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('emailType'),
          disabled: isOauthSuccessful
        }}
      />

      <WizardInput
    type="select"
    label="Email Provider*"
    name="email provider"
    placeholder="Select email provider"
    errors={errors}
    options={['Outlook', 'Gmail']}
    formGroupProps={{
      className: 'mb-3'
    }}
    formControlProps={{
      ...register('emailProvider'),
      disabled: isOauthSuccessful
    }}
  />

    <WizardInput
          type="select"
          label="Email Schedule*"
          name="email type"
          placeholder="Select processing type"
          errors={errors}
          options={['Real-Time', 'Batch']}
          formGroupProps={{
            className: 'mb-3'
          }}
          formControlProps={{
            ...register('emailschedule'),
            disabled: isOauthSuccessful
          }}
        />

      <WizardInput
        label="Ai Name*"
        name="name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('name'),
          disabled: isOauthSuccessful

        }}
      />

      <WizardInput
        type="email"
        errors={errors}
        label="Email*"
        name="email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('email', {
            required: 'Email field is required',
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Email must be valid'
            }
          }),
          disabled: isOauthSuccessful
        }}
      />

  {/* 
      <Row className="g-2 mb-3">
        <WizardInput
          type="password"
          errors={errors}
          label="Password*"
          name="password"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('password', {
              required: 'You must specify a password',
              minLength: {
                value: 2,
                message: 'Password must have at least 2 characters'
              }
            })
          }}
        />
        <WizardInput
          type="password"
          errors={errors}
          label="Confirm Password*"
          name="confirmPassword"
          formGroupProps={{ as: Col, sm: 6 }}
          formControlProps={{
            ...register('confirmPassword', {
              required: 'Confirm Password field is required',
              validate: value =>
                value === watch('password') || 'The password do not match'
            })
          }}
        />
      </Row>
     */}

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the <Link to="#!"> terms</Link> and{' '}
            <Link to="#!"> privacy policy</Link>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            disabled: isOauthSuccessful,
            required: 'You need to agree the terms and privacy policy.'
          })
        }}
      />
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default AccountForm;
