import React, { useContext } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, getLabel, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { tooltipFormatter } from 'helpers/echart-utils';
import AppContext from 'context/Context';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data, isDark, filter) => ({
  color: [
    getColor('primary'),
    getColor('info'),
    getColor('danger'),
    isDark ? '#f5803e' : rgbaColor(getColor('warning'), 1),
  ],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('primary') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },
  toolbox: {
    feature: {
      magicType: {
        type: ['stack', 'tiled']
      }
    },
    right: 0
  },
  legend: {
    data: [
      'Customer inquiry',
      'Customer feedback',
      'Customer complaint',
      'Other'
    ],
    textStyle: {
      color: getColor('gray-600')
    },
    left: 0
  },
  xAxis: {
    type: 'value',
    axisLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-500')
    },
    splitLine: {
      lineStyle: {
        show: true,
        color: getColor('gray-200')
      }
    }
  },
  yAxis: {
    type: 'category',
    data: getLabel(filter),
    axisLine: {
      lineStyle: {
        show: true,
        color: getColor('gray-300')
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-500'),
    }
  },
  series: [
    {
      name: 'Customer inquiry',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#fff'
      },
      emphasis: {
        focus: 'series'
      },
      data: getLabel(filter).map(label => {
        return data['Customer inquiry']?.chartData[label] 
      })
    },
    {
      name: 'Customer feedback',
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: getLabel(filter).map(label => {
        return data['Customer feedback']?.chartData[label] 
      })
    },
    {
      name: 'Customer complaint',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#fff'
      },
      emphasis: {
        focus: 'series'
      },
      data: getLabel(filter).map(label => {
        return data['Customer complaint']?.chartData[label] 
      })
    },
    {
      name: 'Other',
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: '#fff'
      },
      emphasis: {
        focus: 'series'
      },
      data: getLabel(filter).map(label => {
        return data['Other']?.chartData[label] 
      })
    }
  ],
  grid: {
    right: 15,
    left: 5,
    bottom: 5,
    top: '15%',
    containLabel: true
  }
});

const ConversationsStackedBarChart = ({data, filter}) => {
  const { config } = useContext(AppContext);
  const { isDark } = config;
  
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(data, isDark, filter)}
      style={{height: "25rem", width: "100%"}}
    />
  );
};

export default ConversationsStackedBarChart;
