import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TicketStatusItem from "./TicketStatusItem";
import axiosClient from "config/axios";
import { Card, Placeholder } from "react-bootstrap";
import { statusStyle } from "data/dashboard/ai-support";


const TicketStatus = () => {
  const { emailAccountId } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [emailAccountId]);

  const fetchData = async () => {
    try {
      const params = {
        filterType: 'week',
        emailAccountId: emailAccountId
      };
      const response = await axiosClient.get(`/conversation/tickets-status`, {
        params: params
      });
      setData(response.data.ticketStatuses);
      setIsLoading(false)
      setHasError(false)
    } catch (error) {
      setIsLoading(false)
      setHasError(true)
      console.log(error);
    }
  };


  return (
    <Card className="h-100">
      <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
        <h6 className="mb-2 mb-md-0 py-md-2">
          Ticket Status Count - Weekly Overview
        </h6>
      </Card.Header>
      {isLoading ? (
        <Placeholder as={Card.Text} animation="glow">
          <div
            className="w-100 placeholder rounded"
            style={{ height: 200 }}
          ></div>
        </Placeholder>
      ) : (
        <Card.Body className="d-flex flex-column flex-xl-row">
          {
            !hasError && data 
            ? Object.keys(statusStyle).map((key, index) => (
              <TicketStatusItem
                key={statusStyle[key].title}
                styles={statusStyle[key]}
                singleData={data[key]}
                index={index}
              />
            ))
            : <div className="py-5 m-auto">No data</div>
          }
        </Card.Body>
      )}
    </Card>
  );
};


export default TicketStatus;