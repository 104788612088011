import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useEffect, useState } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import ConversationsListHeader from './ConversationsListHeader';
import { columns } from './ConversationsLayout';
import { useParams } from 'react-router-dom';
import axiosClient from 'config/axios';

const ConversationsList = () => {
  const { userId } = useParams();
  const [data, setDate] = useState(null);
  const [primaryTickets, setPrimaryTickets] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  const handleTicketsSearch = text => {
    const filteredTickets = data.filter(
      ticket =>
        ticket.email?.toLowerCase().includes(text.toLowerCase()) ||
        ticket.subject?.toLowerCase().includes(text.toLowerCase()) ||
        ticket.status?.toLowerCase().includes(text.toLowerCase()) ||
        ticket.sentiment?.toLowerCase().includes(text.toLowerCase()) ||
        ticket.classification?.toLowerCase().includes(text.toLowerCase()) 
    );
    setPrimaryTickets(filteredTickets);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const params = { userId: userId };
      const response = await axiosClient.get(`/allConversations`, { params });
      setDate(response.data.conversations);
      setPrimaryTickets(response.data.conversations);
      setHasError(false);
      setIsLoading(false)
    } catch (error) {
      setHasError(true);
      setIsLoading(false)
      
      console.log(error);
    }
  };
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={primaryTickets}
      sortable
      pagination
    >
      <Card>
        <Card.Header className="border-bottom border-200 px-0">
          <ConversationsListHeader
            table
            handleTicketsSearch={handleTicketsSearch}
          />
        </Card.Header>
        {isLoading ? (
          <Placeholder as={Card.Text} animation="glow">
            <div
              className="w-100 placeholder rounded"
              style={{ height: 540 }}
            ></div>
          </Placeholder>
        ) : (
         !hasError && primaryTickets.length ? <>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-light text-800 align-middle"
                rowClassName="btn-reveal-trigger align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </>
          :<div className="py-5 text-center">No data</div>
        )}
      </Card>
    </AdvanceTableWrapper>
  );
};

export default ConversationsList;
