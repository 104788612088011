import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import React from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConversationsListHeader = ({
  handleTicketsSearch
}) => {
  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Ticket List</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Search by name"
              onChange={e => handleTicketsSearch(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon="search" className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1 mt-2">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-4"
          iconAlign="middle"
        >
          <span className="d-none d-sm-inline-block ms-1">Filter</span>
        </IconButton>
       
      </div>
    </div>
  );
};

ConversationsListHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  layout: PropTypes.string,
  setLayout: PropTypes.func,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func
};

export default ConversationsListHeader;
