import React, { useContext, useEffect, useState } from 'react';

import { Accordion, Form } from 'react-bootstrap';
import WizardInput from './WizardInput';
import AdditionalInformationField from './AdditionalInformationField';
import { MEDICAL } from 'data/common';
import { AuthWizardContext } from 'context/Context';
import Select from 'react-select';
import FrequentlyAskedQuestionsField from './FrequentlyAskedQuestionsField';
import { useForm } from 'react-hook-form';

const MedicalBusinessWizard = ({ errors, setValue, bussinessType }) => {
  const { register, getValues } = useForm();
  const { emailAccount } = useContext(AuthWizardContext);

  var defaulConfig = {};
  if (
    emailAccount.configuration &&
    emailAccount.configuration.gptConfig.configuration.about === MEDICAL
  ) {
    defaulConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
  }

  const [activeKey, setActiveKey] = useState('0');
  const [bussinessConfig, setBussinessConfig] = useState({});
  const [configData, setConfigData] = useState(defaulConfig);
  const [servicesOffered, setServicesOffered] = useState(null);
  const [providers, setProviders] = useState(null);

  const getBussinessWithCurrentFields = () => {
    const businessConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
    const config = {};
    const formFields = Object.keys(getValues());

    // Remove old config field
    formFields.map(field => {
      const parrentKey = field.split('->')[0];
      const childKey = field.split('->')[1];
      if (!config[parrentKey]) config[parrentKey] = {};
      if (businessConfig[parrentKey] && businessConfig[parrentKey][childKey]) {
        config[parrentKey][childKey] = businessConfig[parrentKey][childKey];
      }
    });
    return config;
  };

  const setBussinessConfigData = (infoType, key, data) => {
    const updatedData = bussinessConfig;
    if (!updatedData[infoType]) {
      updatedData[infoType] = {};
    }
    updatedData[infoType][key] = data;

    setBussinessConfig(pre => ({
      ...pre,
      ...updatedData
    }));
  };

  useEffect(() => {
    console.log(bussinessConfig);
    setValue('bussinessConfig', bussinessConfig);
  }, [bussinessConfig]);

  useEffect(() => {
    if (emailAccount.configuration) {
      const config = getBussinessWithCurrentFields();
      if (
        config['Services Offered'] &&
        config['Services Offered']['Services Offered']
      ) {
        const multiServiceOffered = config['Services Offered'][
          'Services Offered'
        ].map(item => ({ value: item, label: item }));
        setServicesOffered(multiServiceOffered);
      }

      if (
        config['Billing & Insurance'] &&
        config['Billing & Insurance']['Accepted Insurance Providers']
      ) {
        const multityoeOfProducts = config['Billing & Insurance'][
          'Accepted Insurance Providers'
        ].map(item => ({ value: item, label: item }));
        setProviders(multityoeOfProducts);
      }

      setConfigData(config);
      setBussinessConfig(config);
    }
  }, [emailAccount]);

  useEffect(() => {
    if (emailAccount.configuration) {
      if (
        emailAccount.configuration.gptConfig.configuration.about !== MEDICAL
      ) {
        setValue('bussinessConfig', {});
        setBussinessConfig({});
      } else {
        setBussinessConfig(getBussinessWithCurrentFields());
      }
    }
  }, [bussinessType]);

  return (
    <Accordion
      activeKey={activeKey}
      onSelect={eventKey => setActiveKey(eventKey)}
    >
      <Accordion.Item eventKey="1">
        <Accordion.Header>Clinic Information</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <WizardInput
            label="Clinic Name"
            defaultValue={
              configData['Clinic Information']
                ? configData['Clinic Information']['Clinic Name']
                : null
            }
            errors={errors}
            formControlProps={{
              ...register('Clinic Information->Clinic Name'),
              onChange: e =>
                setBussinessConfigData(
                  'Clinic Information',
                  'Clinic Name',
                  e.target.value
                )
            }}
          />

          <WizardInput
            type="select"
            label="Type of Clinic"
            defaultValue={
              configData['Clinic Information']
                ? configData['Clinic Information']['Type of Clinic']
                : null
            }
            options={['General Practice', 'Pediatric', 'Dental', 'Dermatology']}
            errors={errors}
            formControlProps={{
              ...register('Clinic Information->Type of Clinic'),
              onChange: e =>
                setBussinessConfigData(
                  'Clinic Information',
                  'Type of Clinic',
                  e.target.value
                )
            }}
          />

          <Form.Group>
            <Form.Label>Number of Practitioners</Form.Label>
            <Form.Control
              type="number"
              defaultValue={
                configData['Clinic Information']
                  ? configData['Clinic Information']['Number of Practitioners']
                  : null
              }
              {...register('Clinic Information->Number of Practitioners')}
              onChange={e =>
                setBussinessConfigData(
                  'Clinic Information',
                  'Number of Practitioners',
                  e.target.value
                )
              }
            />
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Billing & Insurance</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <Form.Group>
            <Form.Label>Accepted Insurance Providers</Form.Label>

            <Select
              options={[
                'UnitedHealthcare',
                'Blue Cross Blue Shield (various regional affiliates)',
                'Anthem',
                'Aetna',
                'Cigna',
                'Humana',
                'Kaiser Permanente (available in certain regions)'
              ].map(item => ({
                label: item,
                value: item
              }))}
              placeholder="Select..."
              isMulti
              classNamePrefix="react-select"
              value={providers}
              {...register('Billing & Insurance->Accepted Insurance Providers')}
              onChange={selectedOptions => {
                const selectedValues = selectedOptions
                  ? selectedOptions.map(option => option.value)
                  : [];
                setProviders(selectedOptions);
                setBussinessConfigData(
                  'Billing & Insurance',
                  'Accepted Insurance Providers',
                  selectedValues
                );
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Payment Methods Accepted</Form.Label>

            {['Cash', 'Credit Card', 'Online Transfer'].map(label => (
              <Form.Check
                key={label}
                type="radio"
                label={label}
                checked={
                  bussinessConfig['Billing & Insurance']
                    ? bussinessConfig['Billing & Insurance'][
                        'Payment Methods Accepted'
                      ] === label
                    : null
                }
                name="Payment Methods Accepted"
                {...register('Billing & Insurance->Payment Methods Accepted')}
                onChange={() =>
                  setBussinessConfigData(
                    'Billing & Insurance',
                    'Payment Methods Accepted',
                    label
                  )
                }
              />
            ))}
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Services Offered</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <Form.Group>
            <Form.Label>List of Services</Form.Label>

            {['Consultation', 'Vaccination', 'Surgery', 'Physiotherapy'].map(
              label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Services Offered']
                      ? bussinessConfig['Services Offered'][
                          'List of Services'
                        ] === label
                      : null
                  }
                  name="List of Services"
                  {...register('Services Offered->List of Services')}
                  onChange={() =>
                    setBussinessConfigData(
                      'Services Offered',
                      'List of Services',
                      label
                    )
                  }
                />
              )
            )}
          </Form.Group>
          <WizardInput
            type="textarea"
            label="Specialized Services"
            defaultValue={
              configData['Services Offered']
                ? configData['Services Offered']['Specialized Services']
                : null
            }
            errors={errors}
            formControlProps={{
              ...register('Services Offered->Specialized Services'),
              onChange: e =>
                setBussinessConfigData(
                  'Services Offered',
                  'Specialized Services',
                  e.target.value
                )
            }}
          />

          <Form.Group>
            <Form.Label>Services Offered</Form.Label>
            <Select
              options={[
                'Cardiology',
                'Dentistry',
                'Dermatology',
                'Emergency Medicine'
              ].map(item => ({
                label: item,
                value: item
              }))}
              isMulti
              classNamePrefix="react-select"
              value={servicesOffered}
              {...register('Services Offered->Services Offered')}
              onChange={selectedOptions => {
                const selectedValues = selectedOptions
                  ? selectedOptions.map(option => option.value)
                  : [];
                setServicesOffered(selectedOptions);
                setBussinessConfigData(
                  'Services Offered',
                  'Services Offered',
                  selectedValues
                );
              }}
            />
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>Online Resources & Forms</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <WizardInput
            label="Patient Intake Form Link"
            name="Patient Intake Form Link"
            defaultValue={
              configData['Online Resources & Forms']
                ? configData['Online Resources & Forms'][
                    'Patient Intake Form Link'
                  ]
                : null
            }
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register(
                'Online Resources & Forms->Patient Intake Form Link',
                {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }
              ),
              onChange: e =>
                setBussinessConfigData(
                  'Online Resources & Forms',
                  'Patient Intake Form Link',
                  e.target.value
                )
            }}
          />

          <WizardInput
            label="Online Appointment Booking Link"
            name="Online Appointment Booking Link"
            defaultValue={
              configData['Online Resources & Forms']
                ? configData['Online Resources & Forms'][
                    'Online Appointment Booking Link'
                  ]
                : null
            }
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register(
                'Online Resources & Forms->Online Appointment Booking Link',
                {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }
              ),
              onChange: e =>
                setBussinessConfigData(
                  'Online Resources & Forms',
                  'Online Appointment Booking Link',
                  e.target.value
                )
            }}
          />

          <WizardInput
            label="Patient Portal Access Link"
            name="Patient Portal Access Link"
            defaultValue={
              configData['Online Resources & Forms']
                ? configData['Online Resources & Forms'][
                    'Patient Portal Access Link'
                  ]
                : null
            }
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register(
                'Online Resources & Forms->Patient Portal Access Link',
                {
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/i,
                    message: 'Website URL must be valid'
                  }
                }
              ),
              onChange: e =>
                setBussinessConfigData(
                  'Online Resources & Forms',
                  'Patient Portal Access Link',
                  e.target.value
                )
            }}
          />

          <WizardInput
            label="Telemedicine Link"
            name="Telemedicine Link"
            defaultValue={
              configData['Online Resources & Forms']
                ? configData['Online Resources & Forms']['Telemedicine Link']
                : null
            }
            placeholder="If the clinic offers telemedicine services, provide the link here."
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('Online Resources & Forms->Telemedicine Link', {
                pattern: {
                  value: /^(ftp|http|https):\/\/[^ "]+$/i,
                  message: 'Website URL must be valid'
                }
              }),
              onChange: e =>
                setBussinessConfigData(
                  'Online Resources & Forms',
                  'Telemedicine Link',
                  e.target.value
                )
            }}
          />

          <WizardInput
            label="Feedback Form Link"
            name="Feedback Form Link"
            defaultValue={
              configData['Online Resources & Forms']
                ? configData['Online Resources & Forms']['Feedback Form Link']
                : null
            }
            placeholder="Link to a form where patients can provide feedback on their experience."
            errors={errors}
            formGroupProps={{
              className: 'mb-3'
            }}
            formControlProps={{
              className: 'input-spin-none',
              ...register('Online Resources & Forms->Feedback Form Link', {
                pattern: {
                  value: /^(ftp|http|https):\/\/[^ "]+$/i,
                  message: 'Website URL must be valid'
                }
              }),
              onChange: e =>
                setBussinessConfigData(
                  'Online Resources & Forms',
                  'Feedback Form Link',
                  e.target.value
                )
            }}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>Staff Information</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <Form.Group>
            <Form.Label>Number of Medical Staff</Form.Label>
            <Form.Control
              type="number"
              defaultValue={
                configData['Staff Information']
                  ? configData['Staff Information']['Number of Medical Staff']
                  : null
              }
              {...register('Staff Information->Number of Medical Staff')}
              onChange={e =>
                setBussinessConfigData(
                  'Staff Information',
                  'Number of Medical Staff',
                  e.target.value
                )
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Number of Administrative Staff</Form.Label>
            <Form.Control
              type="number"
              defaultValue={
                configData['Staff Information']
                  ? configData['Staff Information'][
                      'Number of Administrative Staff'
                    ]
                  : null
              }
              {...register('Staff Information->Number of Administrative Staff')}
              onChange={e =>
                setBussinessConfigData(
                  'Staff Information',
                  'Number of Administrative Staff',
                  e.target.value
                )
              }
            />
          </Form.Group>

          <WizardInput
            type="textarea"
            label="Practitioner Profiles"
            defaultValue={
              configData['Staff Information']
                ? configData['Staff Information']['Practitioner Profiles']
                : null
            }
            placeholder="Brief profiles or bios for each practitioner, including their specialties."
            errors={errors}
            formControlProps={{
              ...register('Staff Information->Practitioner Profiles'),
              onChange: e =>
                setBussinessConfigData(
                  'Staff Information',
                  'Practitioner Profiles',
                  e.target.value
                )
            }}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>Emergency Services</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <Form.Group>
            <Form.Label>Availability of Emergency Services</Form.Label>
            {['Yes', 'No'].map(label => (
              <Form.Check
                key={label}
                type="radio"
                label={label}
                checked={
                  bussinessConfig['Emergency Services']
                    ? bussinessConfig['Emergency Services'][
                        'Availability of Emergency Services'
                      ] === label
                    : null
                }
                name="Availability of Emergency Services"
                {...register(
                  'Emergency Services->Availability of Emergency Services'
                )}
                onChange={() =>
                  setBussinessConfigData(
                    'Emergency Services',
                    'Availability of Emergency Services',
                    label
                  )
                }
              />
            ))}
          </Form.Group>

          <Form.Group>
            <Form.Label>Ambulance Availability</Form.Label>

            {['Yes', 'No'].map(label => (
              <Form.Check
                key={label}
                type="radio"
                label={label}
                checked={
                  bussinessConfig['Emergency Services']
                    ? bussinessConfig['Emergency Services'][
                        'Ambulance Availability'
                      ] === label
                    : null
                }
                name="Ambulance Availability"
                {...register('Emergency Services->Ambulance Availability')}
                onChange={() =>
                  setBussinessConfigData(
                    'Emergency Services',
                    'Ambulance Availability',
                    label
                  )
                }
              />
            ))}
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>Legal & Compliance</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <WizardInput
            type="text"
            label="Medical License Number"
            defaultValue={
              configData['Legal & Compliance']
                ? configData['Legal & Compliance']['Medical License Number']
                : null
            }
            errors={errors}
            formControlProps={{
              ...register('Legal & Compliance->Medical License Number'),
              onChange: e =>
                setBussinessConfigData(
                  'Legal & Compliance',
                  'Medical License Number',
                  e.target.value
                )
            }}
          />
          <Form.Group>
            <Form.Label>Health and Safety Compliance</Form.Label>

            {['yes', 'No'].map(label => (
              <Form.Check
                key={label}
                type="radio"
                label={label}
                checked={
                  bussinessConfig['Legal & Compliance']
                    ? bussinessConfig['Legal & Compliance'][
                        'Health and Safety Compliance'
                      ] === label
                    : null
                }
                name="Health and Safety Compliance"
                {...register(
                  'Legal & Compliance->Health and Safety Compliance'
                )}
                onChange={() =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Health and Safety Compliance',
                    label
                  )
                }
              />
            ))}
          </Form.Group>

          <WizardInput
            type="textarea"
            label="Patient Data Protection Measures"
            defaultValue={
              configData['Legal & Compliance']
                ? configData['Legal & Compliance'][
                    'Patient Data Protection Measures'
                  ]
                : null
            }
            errors={errors}
            formControlProps={{
              ...register(
                'Legal & Compliance->Patient Data Protection Measures'
              ),
              onChange: e =>
                setBussinessConfigData(
                  'Legal & Compliance',
                  'Patient Data Protection Measures',
                  e.target.value
                )
            }}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>Frequently Asked Questions</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-3">
          <FrequentlyAskedQuestionsField
            confg={bussinessConfig}
            setConfig={setBussinessConfig}
            data={configData['Frequently Asked Questions']}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="9">
        <Accordion.Header>Additional Information</Accordion.Header>
        <Accordion.Body className="d-flex flex-column gap-2">
          <AdditionalInformationField
            confg={bussinessConfig}
            setConfig={setBussinessConfig}
            data={configData['Additional Information']}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default MedicalBusinessWizard;
