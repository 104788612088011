import React from 'react';
import {  Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import TicketsPreview from 'components/app/ai-support/tickets-preview/TicketsPreview';
import Dashboard from 'components/dashboards/default';
import AddEmail from 'components/dashboards/ai-support/AddEmail';

import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import ProtectedRoute from './ProtectedRoute';
import EmailDashboard from 'components/dashboards/ai-support';
import EditEmail from 'components/dashboards/ai-support/edit-email';
import RefundRequestForm from 'components/request/refund-Request'
import Action from 'components/action/Action';



const AppRoutes = () => {
  return (
    <Routes>
      <Route path="landing" element={<Landing />} />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route path="/login" element={<SimpleLogin />} />
        <Route
          path="/register"
          element={<SimpleRegistration />}
        />
        <Route path="/logout" element={<SimpleLogout />} />
        <Route
          path="/forgot-password"
          element={<SimpleForgetPassword />}
        />
        <Route
          path="/reset-password"
          element={<SimplePasswordReset />}
        />
        <Route
          path="/confirm-mail"
          element={<SimpleConfirmMail />}
        />
        <Route
          path="/lock-screen"
          element={<SimpleLockScreen />}
        />
      </Route>

      <Route path="/conversation/update-status/:conversationId" element={<Action />} />
      
      <Route element={<MainLayout />}>
        <Route element={<ProtectedRoute/>}>

          <Route path="/" element={<Dashboard />} />
          <Route path="email/:emailAccountId" element={<EmailDashboard />} />
          <Route path="/email/ticket/:ticketId" element={<TicketsPreview />} />
          <Route path="/email/AddEmail" element={<AddEmail />} />
          <Route path="/email/:emailAccountId/update" element={<EditEmail />} />
          
        </Route>
      </Route>
      <Route path="/refund-request/:conversationId" element={<RefundRequestForm />} />

      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default AppRoutes;
