import { faClinicMedical, faCoins, faHouseUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export const DAY = 'day';                    
export const WEEK = 'week';                     // Mon -> Sun
export const MONTH = 'month';                   // 30 day of month
export const MONTHS = 'months';                 // Jan -> DEC
export const YEARS = 'years';                   // 2023, 2022, ...

export const ECOMMERCE = 'E-commerce';
export const MEDICAL = 'Medical';
export const FINANCIAL_SERVICESA = 'Financial services';
export const REAL_ESTATE = 'Real estate';

export const industryIcon = {
  'E-commerce': faShoppingCart,
  'Medical': faClinicMedical,
  'Financial services': faCoins,
  'Real estate': faHouseUser,
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const quarters = ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter']

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const daysShot = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
];

export const OPEN = 'Open'
export const CLOSED = 'Closed'
export const ESCALATED = 'Escalated'
export const IN_PROGRESS = 'In Progress'
export const REOPENED = 'Reopened'
export const RESOLVED = 'Resolved'
export const KEEP_IN_PROGESS = 'Keep in progress'
export const RATE_AGENT = 'Rate agent'
export const OPEN_ESCALATION = 'Open Escalation'
export const CLOSED_ESCALATION = 'Closed Escalation'

export const SYSTEM = 'System'
export const AGENT = 'Agent'
export const USER = 'User'

