// import IconButton from 'components/common/IconButton';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
// import WizardInput from 'components/wizard/WizardInput';
import axiosClient from 'config/axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
// import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
// import { toast } from 'react-toastify';

const Action = () => {
  // const { handleSubmit, register, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  // const onSubmitData = async data => {
  //   try {
  //     const result = await axiosClient.post(
  //       `/conversation/update-status/${conversationId}?status=${status}`,
  //       data
  //     );
  //     toast.success(`Success to escalate tickets`, {
  //       theme: 'colored'
  //     });
  //     setMessage(result.data.message);
  //   } catch (error) {
  //     toast.error(`Failed to escalate tickets`, {
  //       theme: 'colored'
  //     });
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axiosClient.post(
        `/conversation/update-status/${conversationId}?status=${status}`
      );
      setMessage(result.data.message);
      setIsLoading(true)
    };
    fetchData();
  }, [conversationId, status]);
  
  return (
    isLoading && (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={6} className="col-xxl-6">
          <Logo />
          <Card>
            <Card.Body className="p-4 p-sm-5">
              {message && (
                <div>
                  {message.split('<br>').map((item, key) => (
                    <p key={key} className="m-0 text-center">
                      {item}
                    </p>
                  ))}
                </div>
              )}
              {/* {status === 'Escalated' && !message && (
                <div className="d-flex flex-column">
                  <WizardInput
                    type="textarea"
                    label="Escalation reason*"
                    name="escalationReason"
                    placeholder="Enter escalation reason..."
                    errors={errors}
                    formGroupProps={{
                      className: 'mb-3'
                    }}
                    formControlProps={{
                      ...register('escalationReason', {
                        required: 'Escalation reason is required'
                      })
                    }}
                  />
                  <IconButton
                    variant="primary"
                    className="mx-auto px-5"
                    type="submit"
                    transform="down-1 shrink-4"
                    onClick={handleSubmit(onSubmitData)}
                  >
                    Submit
                  </IconButton>
                </div>
              )} */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
    )
  );
};

export default Action;
