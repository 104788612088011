import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Dropdown, Image } from 'react-bootstrap';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';


function ImageUpload({ onFilesChange }) {
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            setFiles(currentFiles => [...currentFiles, ...newFiles]);
            onFilesChange([...files, ...newFiles]);
        },
        maxFiles: 4
    });

    const handleRemove = fileToRemove => {
        const newFiles = files.filter(file => file.path !== fileToRemove.path);
        setFiles(newFiles);
        onFilesChange(newFiles);
    };

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center' }}>
                    <img src={cloudUpload} alt="Upload" width={25} />
                    <p>Drop your images here (max 4)</p>
                </div>
            </div>
            <div className="mt-3">
                {files.map(file => (
                    <div key={file.path} className="d-flex align-items-center py-3">
                        <Image rounded src={file.preview} alt={file.name} width={40} height={40} />
                        <div className="ms-3">
                            <h6>{file.name}</h6>
                            <p>Size: {file.size} bytes</p>
                            <Dropdown.Item className="text-danger" onClick={() => handleRemove(file)}>
                                Remove
                            </Dropdown.Item>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default ImageUpload;
