import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Form, Placeholder, Row, Spinner } from 'react-bootstrap';
import ConversationsStackedBarChart from './ConversationsStackedBarChart';
import DashboardPieChart from 'components/dashboards/chart/DashboardPieChart';
import { MONTHS, WEEK, YEARS } from 'data/common';
import { getColor, getTotalNumber, rgbaColor } from 'helpers/utils';
import AppContext from 'context/Context';
import axiosClient from 'config/axios';
import { useParams } from 'react-router-dom';
import Percentage from '../../Percentage';
import Flex from 'components/common/Flex';

const ConversationsClassification = () => {
  const { config } = useContext(AppContext);
  const { isDark } = config;

  const { emailAccountId } = useParams();
  const [dataWeekly, setDataWeekly] = useState(null);
  const [dataOverTime, setDataOverTime] = useState(null);
  const [selectedOption, setSelectedOption] = useState(WEEK);

  const [isFirstLoadingOverTime, setIsFirstLoadingOverTime] = useState(true);
  const [isLoadingOverTime, setIsLoadingOverTime] = useState(true);
  const [hasErrorOverTime, setHasErrorOverTime] = useState(false);
  const [isLoadingWeekly, setIsLoadingWeekly] = useState(true);
  const [hasErrorWeekly, setHasErrorWeekly] = useState(false);

  const pieChartColor = [
    getColor('primary'),
    getColor('info'),
    getColor('danger'),
    isDark ? '#f5803e' : rgbaColor(getColor('warning'), 1)
  ];

  const handleFiltering = e => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
  };

  const fetchWeeklyData = async () => {
    try {
      const params = { filterType: 'week', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/classification`, {
        params
      });
      setDataWeekly({
        'Customer inquiry': response.data.classification['Customer inquiry'],
        'Customer feedback': response.data.classification['Customer feedback'],
        'Customer complaint':
          response.data.classification['Customer complaint'],
        Other: response.data.classification['Other']
      });
      setIsLoadingWeekly(false);
      setHasErrorWeekly(false);
    } catch (error) {
      setIsLoadingWeekly(false);
      setHasErrorWeekly(true);
      console.log(error);
    }
  };

  const fetchOverTimeData = async () => {
    try {
      const params = {
        filterType: selectedOption,
        emailAccountId: emailAccountId
      };
      const response = await axiosClient.get(`/conversation/classification`, {
        params
      });
      setDataOverTime({
        'Customer inquiry': response.data.classification['Customer inquiry'],
        'Customer feedback': response.data.classification['Customer feedback'],
        'Customer complaint':
          response.data.classification['Customer complaint'],
        Other: response.data.classification['Other']
      });
      setIsFirstLoadingOverTime(false);
      setIsLoadingOverTime(false);
      setHasErrorOverTime(false);
    } catch (error) {
      setIsFirstLoadingOverTime(false)
      setIsLoadingOverTime(false);
      setHasErrorOverTime(true);
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoadingOverTime(true);
    fetchOverTimeData();
  }, [selectedOption, emailAccountId]);

  useEffect(() => {
    fetchWeeklyData();
  }, [emailAccountId]);
  return (
    <>
      <Col xxl={8}>
        <Card className="card h-100">
          <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
            <h6 className="mb-2 mb-md-0 py-md-2">
              Ticket Classification Over Time
            </h6>
            <Row className="flex-between-center g-0">
              <Col xs="auto">
                <Form.Select
                  value={selectedOption}
                  onChange={handleFiltering}
                  size="sm"
                >
                  <option value={WEEK}>Week</option>
                  <option value={MONTHS}>Months</option>
                  <option value={YEARS}>Years</option>
                </Form.Select>
              </Col>
            </Row>
          </Card.Header>
          {isFirstLoadingOverTime ? (
            <Placeholder as={Card.Text} animation="glow">
              <div
                className="w-100 placeholder rounded"
                style={{ height: 430 }}
              ></div>
            </Placeholder>
          ) : (
            <Card.Body
              className="p-3 d-flex aligm-items-center"
              style={{ height: 430 }}
            >
              {isLoadingOverTime ? (
                <Flex
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 430 }}
                  className="position-absolute start-50 top-50 translate-middle"
                >
                  <Spinner animation="border" role="status"></Spinner>
                  <span className="mt-2">Loading...</span>
                </Flex>
              ) : !hasErrorOverTime && dataOverTime ? (
                <ConversationsStackedBarChart
                  data={dataOverTime}
                  filter={selectedOption}
                />
              ) : (
                <div className="py-5 m-auto">No data</div>
              )}
            </Card.Body>
          )}
        </Card>
      </Col>
      <Col xxl={4}>
        <Card className="card h-100">
          <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
            <h6 className="mb-2 mb-md-0 py-md-2">
              Ticket Classification - Weekly Overview
            </h6>
          </Card.Header>
          {isLoadingWeekly ? (
            <Placeholder as={Card.Text} animation="glow">
              <div
                className="w-100 placeholder rounded"
                style={{ height: 430 }}
              ></div>
            </Placeholder>
          ) : !hasErrorWeekly && dataWeekly ? (
            <>
              <Card.Body className="p-3 d-flex aligm-items-center">
                <DashboardPieChart
                  chartData={dataWeekly}
                  color={pieChartColor}
                />
              </Card.Body>
              <Card.Footer className="py-0">
                <h6 className="text-600 my-2 fs--2 text-center">
                  Totals & Difference: This Week - Last Week
                </h6>
                <Row>
                  <Col
                    xs={6}
                    className="border-end border-top border-200 py-3 ps-4"
                  >
                    <div>
                      <h6 className="text-600 mb-1 fs--2">Customer inquiry</h6>
                      <h6 className="fs-0 mb-0 d-flex align-items-center">
                        {getTotalNumber(
                          dataWeekly['Customer inquiry']?.chartData
                        )}
                        <Percentage
                          percentage={
                            dataWeekly['Customer inquiry']?.percentage | 0
                          }
                          noBackground={true}
                          className={'fs--2'}
                        />
                      </h6>
                    </div>
                  </Col>
                  <Col xs={6} className="border-top border-200 py-3 ps-4">
                    <div>
                      <h6 className="text-600 mb-1 fs--2">Customer feedback</h6>
                      <h6 className="fs-0 mb-0 d-flex align-items-center">
                        {getTotalNumber(
                          dataWeekly['Customer feedback']?.chartData
                        )}
                        <Percentage
                          percentage={
                            dataWeekly['Customer feedback']?.percentage | 0
                          }
                          noBackground={true}
                          className={'fs--2'}
                        />
                      </h6>
                    </div>
                  </Col>
                  <Col
                    xs={6}
                    className="border-end border-top border-200 py-3 ps-4"
                  >
                    <div>
                      <h6 className="text-600 mb-1 fs--2">
                        Customer complaint
                      </h6>
                      <h6 className="fs-0 mb-0 d-flex align-items-center">
                        {getTotalNumber(
                          dataWeekly['Customer complaint']?.chartData
                        )}
                        <Percentage
                          percentage={
                            dataWeekly['Customer complaint']?.percentage | 0
                          }
                          noBackground={true}
                          className={'fs--2'}
                        />
                      </h6>
                    </div>
                  </Col>
                  <Col xs={6} className=" border-top py-3 ps-4">
                    <div>
                      <h6 className="text-600 mb-1 fs--2">Other</h6>
                      <h6 className="fs-0 mb-0 d-flex align-items-center">
                        {getTotalNumber(dataWeekly['Other']?.chartData)}
                        <Percentage
                          percentage={dataWeekly['Other']?.percentage | 0}
                          noBackground={true}
                          className={'fs--2'}
                        />
                      </h6>
                    </div>
                  </Col>
                </Row>
              </Card.Footer>
            </>
          ) : (
            <div className="py-5 m-auto">No data</div>
          )}
        </Card>
      </Col>
    </>
  );
};

export default ConversationsClassification;
