import React, {  useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getUser, logout, selectToken } from 'redux/reducer/auth';

const ProtectedRoute = () => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const fetchUserData = async () => {
    try {
      await dispatch(getUser());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData();
    } else {
      dispatch(logout());
    }
  }, []);
  return(
    token ? <Outlet /> :<Navigate to="/login" />
  )
};

export default ProtectedRoute;
