import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {
  const windowWidth = useRef(window.innerWidth);
  const [pagination, setPagination] = useState([]);
  const [paginationInput, setPaginationInput] = useState();
  const allPagination = Array.from(
    { length: pageCount },
    (_, index) => index + 1
  );

  const onPaginationInputChange = e => {
    if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
      setPaginationInput(parseInt(e.target.value));
    }
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      gotoPage(paginationInput - 1)
      setPaginationInput("")
    }
  };

  const getPaginationButtons = (
    numButtons,
    numButtonBeside,
    numButtonOnBothSide
  ) => {
    const currentPage = pageIndex + 1;
    if (
      currentPage > numButtons - numButtonOnBothSide * 2 &&
      currentPage < pageCount - (numButtons - numButtonOnBothSide * 2) + 1
    ) {
      const startArr = allPagination.slice(0, numButtonOnBothSide);
      const middleArr = allPagination.slice(
        currentPage - numButtonBeside / 2 - 1,
        currentPage + numButtonBeside / 2
      );
      const endArr = allPagination.slice(-numButtonOnBothSide);
      const paginationItems = [
        ...startArr,
        '...',
        ...middleArr,
        '...',
        ...endArr
      ];
      return paginationItems;
    } else {
      var startLength = 0;
      var endLength = 0;

      if (currentPage <= numButtons - numButtonOnBothSide * 2) {
        startLength = numButtons - numButtonOnBothSide;
        endLength = numButtons - startLength;
      } else if (
        currentPage >=
        pageCount - (numButtons - numButtonOnBothSide * 2)
      ) {
        endLength = numButtons - numButtonOnBothSide;
        startLength = numButtons - endLength;
      }
      const startArr = allPagination.slice(0, startLength);
      const endArr = allPagination.slice(-endLength);
      const paginationItems = [...startArr, '...', ...endArr];
      return paginationItems;
    }
  };

  useEffect(() => {
    var numButtons = 0;
    var numButtonBeside = 0;
    var numButtonOnBothSide = 0;
    if (windowWidth.current < 768 && pageCount > 5) {
      numButtons = 4;
      numButtonBeside = 0;
      numButtonOnBothSide = 1;
      setPagination(
        getPaginationButtons(numButtons, numButtonBeside, numButtonOnBothSide)
      );
    } else if (pageCount > 10) {
      numButtons = 10;
      numButtonBeside = 4;
      numButtonOnBothSide = 2;
      setPagination(
        getPaginationButtons(numButtons, numButtonBeside, numButtonOnBothSide)
      );
    } else {
      setPagination(allPagination);
    }
  }, [pageIndex]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 me-1">
        {pagination.map((page, index) => (
          <li
            key={index}
            className={classNames({ active: pageIndex === page - 1 })}
          >
            <Button
              size="sm"
              variant="falcon-default"
              className="page ms-1"
              onClick={() => gotoPage(page - 1)}
            >
              {page}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
      <input
        type="text"
        value={paginationInput}
        className="form-control form-control-sm ms-2 px-1 text-center"
        style={{ width: 35 }}
        onChange={onPaginationInputChange}
        onKeyDown={handleKeyDown}
      ></input>
    </Flex>
  );
};

export default AdvanceTablePagination;
