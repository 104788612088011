import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';

import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const AdditionalInformationField = ({
  confg,
  setConfig,
  data,
}) => {
  const [fields, setFields] = useState([{ title: '', content: '' }]);
  const [fieldErrors, setFieldErrors] = useState([{ title: '', content: '' }]);
  const [information, setInformation] = useState([]);

  const validateSingleField = (type, value) => {
    if (value.trim() === '') {
      return `${type.charAt(0).toUpperCase() + type.slice(1)} is required.`;
    }
    return '';
  };

  const handleFieldChange = (type, value, index) => {
    const newFields = [...fields];
    newFields[index][type] = value;
    setFields(newFields);

    const newFieldErrors = [...fieldErrors];
    newFieldErrors[index][type] = validateSingleField(type, value);
    setFieldErrors(newFieldErrors);
  };

  const validateField = (field, index) => {
    const errors = { title: '', content: '' };
    if (!field) return errors.title === '' && errors.content === '';

    if (field.title.trim() === '') {
      errors.title = 'Title is required.';
    }
    if (field.content.trim() === '') {
      errors.content = 'Content is required.';
    }

    const newFieldErrors = [...fieldErrors];
    newFieldErrors[index] = errors;
    setFieldErrors(newFieldErrors);

    return errors.title === '' && errors.content === '';
  };

  const addField = () => {
    const index = fields.length - 1;
    const newField = fields[index];

    if (validateField(newField, index)) {
      setInformation([...information, newField]);
      setFields([...fields, { title: '', content: '' }]);
      setFieldErrors([...fieldErrors, { title: '', content: '' }]);
    }
  };

  const removeField = index => {
    setFields(fields.filter((field, i) => i !== index));
  };

  useEffect(() => {
    const transformedData = fields.reduce((acc, curr) => {
      if (curr.title && curr.content) {
        acc[curr.title] = curr.content;
      }
      return acc;
    }, {});
    var updatedData = confg;
    console.log(confg);
    if(updatedData){
      updatedData['Additional Information'] = transformedData;
    }

    setConfig(pre => ({
      ...pre,
      ...updatedData
    }));
  }, [fields]);

  useEffect(() => {
    if (data) {
      const _fields = [];
      Object.keys(data).map(key => {
        _fields.push({ title: key, content: data[key] });
      });
      setFields(_fields);
    } else {
      console.log(data);
    }
  }, [data]);

    return (
    <>
      {fields.map((field, index) => (
        <div key={index}>
          <Form.Group>
            <Flex justifyContent="between">
              <Form.Label className="mt-auto">Title</Form.Label>

              {/* {index > 0 && ( */}
              <Button
                variant="danger"
                style={{ height: 25, width: 20 }}
                className="d-flex justify-content-center align-items-center"
                onClick={() => removeField(index)}
              >
                <FontAwesomeIcon icon={faMinus} size="md" />
              </Button>
              {/* )} */}
            </Flex>
            <Form.Control
              type="text"
              value={field.title}
              onChange={e => handleFieldChange('title', e.target.value, index)}
            />
            <Form.Control.Feedback type="invalid" className="mt-0 d-flex">
              {fieldErrors[index]?.title}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Content</Form.Label>
            <Form.Control
              as="textarea"
              value={field.content}
              rows={4}
              onChange={e =>
                handleFieldChange('content', e.target.value, index)
              }
            />
            <Form.Control.Feedback type="invalid" className="mt-0 d-flex">
              {fieldErrors[index]?.content}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      ))}
      <Button variant="success" onClick={addField} className="mt-3">
        <FontAwesomeIcon icon={faPlus} /> Add Information
      </Button>
    </>
  );
};

export default AdditionalInformationField;
