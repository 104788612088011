import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AccountForm from './AccountForm';
import BussinessForm from './BussinessForm';
import ConfigurationForm from './ConfigurationForm';
import Success from './Success';
import AppContext, { AuthWizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import WizardModal from './WizardModal';
import Flex from 'components/common/Flex';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import IconAlert from 'components/common/IconAlert';
import { Col, Row } from 'react-bootstrap';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import Lottie from 'lottie-react';
import { Alert } from 'react-bootstrap';
import axiosClient from 'config/axios';
import { toast } from 'react-toastify';

const WizardLayout = ({ variant, validation, progressBar }) => {
  //check to see if we should disable oauth
  const [oauthCompleted, setOauthCompleted] = useState(
    JSON.parse(localStorage.getItem('oauthCompleted'))
  );
  const [showAlert, setShowAlert] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [triggerErrorCheck, setTriggerErrorCheck] = useState(false);
  const [isError, setIsError] = useState(false);

  const { isRTL } = useContext(AppContext);
  const { emailAccount, setEmail, step, setStep } =
    useContext(AuthWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
    control
  } = useForm();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oauthStatus = urlParams.get('oauthStatus');

    if (oauthStatus != null && oauthStatus === 'success') {
      // If OAuth was successful, save the data to localStorage
      setOauthCompleted(true);
      localStorage.setItem('oauthCompleted', 'true');
      //setShowAlert(true);
    } else if (oauthStatus != null && oauthStatus === 'failure') {
      // Handle the OAuth failure
      localStorage.setItem('currentStep', 0);
      localStorage.removeItem('formData');
      localStorage.removeItem('currentStep');
      localStorage.removeItem('oauthCompleted');
      console.error('OAuth process failed.');
      console.error('OAuth process failed.');
      setOauthCompleted(false);
      localStorage.setItem('oauthCompleted', 'false');
      // You can also use a UI component or state to display the error to the user
    }
    const savedFormData = JSON.parse(localStorage.getItem('formData') || '{}');
    const savedCurrentStep = Number(localStorage.getItem('currentStep') || '0');

    // Check if savedFormData is not an empty object
    if (Object.keys(savedFormData).length > 0) {
      // Set the form values using the setValue method from react-hook-form
      Object.keys(savedFormData).forEach(key => {
        setValue(key, savedFormData[key]);
      });
    }

    // Check if savedCurrentStep is not zero
    if (savedCurrentStep > 0) {
      // Set the form values using the setValue method from react-hook-form
      setStep(savedCurrentStep + 1);
      //localStorage.removeItem('currentStep');
    }
  }, []);

  // Empty dependency array means this effect runs once when the component mounts

  const navItems = [
    {
      icon: 'lock',
      label: 'Account'
    },
    {
      icon: faBuilding,
      label: 'Bussiness'
    },
    {
      icon: 'cog',
      label: 'Configuration'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const onSaveData = async data => {
    try {
      //set loading here
      setIsLoading(true);
      const response = await axiosClient.post('/save-email-account', data);

      if (response.status === 200) {
        localStorage.setItem('formData', JSON.stringify(data));
        localStorage.setItem('currentStep', step);
        setIsLoading(false);
        setShowAlert(true);
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_API_URL}/login`;
        }, 6000); // Redirect after 6 seconds
      }
    } catch (error) {
      if (error.response.data.message === 'Email already exists') {
        console.log('Email already exists');

        toast.error(`Email already exists`, {
          theme: 'colored'
        });
        setIsLoading(false);
      } else {
        console.error('Error saving data:', error);
      }
    }
  };

  const onSubmitFinalData = async data => {
    try {
      // Submit the final data to the server

      setIsLoading(true);

      console.log(data);

      const response = await axiosClient.post('/handleEmailSubmit', data);

      setIsLoading(false);
      // Check if the data was submitted successfully
      if (response.status === 200) {
        // Handle successful submission (e.g., redirect the user, show a success message, etc.)
        //Need to remove from stroage so user can start from begining 
        localStorage.removeItem('formData');
        localStorage.removeItem('currentStep');
      } else {
        // Handle any errors that occurred while submitting the data
        console.error('Error submitting data:', response);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const onSubmitData = async data => {
    // Check if the user has already completed OAuth
    //const oauthCompleted = localStorage.getItem('oauthCompleted');
    //save steps in loack here too so user can come back
    //need to set values too
    if (step === 1) {
      // If the user has not completed OAuth, save the data and redirect for OAuth
      if (oauthCompleted === null || oauthCompleted == false) {
        setEmail({ ...emailAccount, ...data });
        await onSaveData(data, step);
      } else {
        setStep(step + 1);
        localStorage.setItem('currentStep', step);
        localStorage.setItem('formData', JSON.stringify(data));
      }
    } else if (step === 4 - 1) {
      setEmail({ ...emailAccount, ...data });
      await onSubmitFinalData(data);
      setStep(step + 1);
      localStorage.setItem('currentStep', step);
      localStorage.setItem('formData', JSON.stringify(data));
    } else {
      setEmail({ ...emailAccount, ...data });
      setStep(step + 1);
      localStorage.setItem('currentStep', step);
      localStorage.setItem('formData', JSON.stringify(data));
    }
  };

  const onError = () => {
    if (validation) {
      // Display the error messages
      setIsError(true);
      setTriggerErrorCheck(true);
      window.scrollTo(0, 0);
      // Set isError back to false after 3 seconds
      setTimeout(() => {
        setIsError(false);
      }, 6000);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  if (isLoading) {
    return (
      <Row className="justify-content-center mb-3 g-3">
        <Col lg={4} className="d-flex justify-content-center">
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{ height: '120px', width: '120px' }}
          />
        </Col>
        <h3 className="text-center">Loading...</h3>
      </Row>
    );
  }

  // let alertStyle = {
  //   width: "200px",  /* change this value to whatever size you want */
  //   height: "200px" /* change this value to whatever size you want */
  // };

  if (showAlert) {
    return (
      <Row className="justify-content-center align-items-center">
        <IconAlert
          style={{ width: '400px', height: '300px' }}
          variant={'info'}
          dismissible
          onClose={() => setShowAlert(false)}
        >
          <p className="mb-0">
            {
              'Your data has been saved! You will now be redirected to your email provider for authentication.'
            }
          </p>
        </IconAlert>
      </Row>
    );
  } else {
    return (
      <>
        {isError && (
          <Alert variant="danger" onClose={() => setIsError(false)} dismissible>
            <Row className="justify-content-center align-items-center">
              <p className="text-center">
                There are validation errors:{' '}
                {Object.values(errors)
                  .map(error => error.message)
                  .join(', ')}
              </p>
            </Row>
          </Alert>
        )}

        <WizardModal modal={modal} setModal={setModal} />
        <Card
          as={Form}
          noValidate
          onSubmit={handleSubmit(onSubmitData, onError)}
          className="theme-wizard mb-5"
        >
          <Card.Header
            className={classNames('bg-light', {
              'px-4 py-3': variant === 'pills',
              'pb-2': !variant
            })}
          >
            <Nav className="justify-content-center" variant={variant}>
              {variant === 'pills'
                ? navItems.map((item, index) => (
                    <NavItemPill
                      key={item.label}
                      index={index + 1}
                      step={step}
                      handleNavs={handleNavs}
                      icon={item.icon}
                      label={item.label}
                    />
                  ))
                : navItems.map((item, index) => (
                    <NavItem
                      key={item.label}
                      index={index + 1}
                      step={step}
                      handleNavs={handleNavs}
                      icon={item.icon}
                      label={item.label}
                    />
                  ))}
            </Nav>
          </Card.Header>
          {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
          <Card.Body className="fw-normal px-md-6 py-4">
            {step === 1 && (
              <AccountForm
                register={register}
                errors={errors}
                watch={watch}
                isOauthSuccessful={oauthCompleted}
              />
            )}
            {step === 2 && (
              <BussinessForm
                register={register}
                errors={errors}
                setValue={setValue}
                watch={watch}
                control={control}
              />
            )}
            {step === 3 && (
              <ConfigurationForm
                register={register}
                errors={errors}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                triggerErrorCheck={triggerErrorCheck}
                setTriggerErrorCheck={setTriggerErrorCheck}
              />
            )}
            {step === 4 && (
              <Success setOauthCompleted={setOauthCompleted} reset={reset} />
            )}
          </Card.Body>
          <Card.Footer
            className={classNames('px-md-6 bg-light', {
              'd-none': step === 4,
              ' d-flex': step < 4
            })}
          >
            <IconButton
              variant="link"
              icon={isRTL ? 'chevron-right' : 'chevron-left'}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames('px-0 fw-semi-bold', {
                'd-none': step === 1
              })}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Prev
            </IconButton>

            <IconButton
              variant="primary"
              className="ms-auto px-5"
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              Next
            </IconButton>
          </Card.Footer>
        </Card>
      </>
    );
  }
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
