import React, { useContext, useEffect, useState } from 'react';

import { Accordion, Form, Row } from 'react-bootstrap';
import WizardInput from './WizardInput';
import FalconReactRange from 'components/doc-components/FalconReactRange';
import AdditionalInformationField from './AdditionalInformationField';
import FrequentlyAskedQuestionsField from './FrequentlyAskedQuestionsField';
import { ECOMMERCE } from 'data/common';
import { AuthWizardContext } from 'context/Context';
import Select from 'react-select';
import { useForm } from 'react-hook-form';

const ECommerceBusinessWizard = ({
  watch,
  errors,
  setValue,
  bussinessType
}) => {
  const { register, getValues } = useForm();
  const { emailAccount } = useContext(AuthWizardContext);

  var defaulConfig = {};
  if (
    emailAccount.configuration &&
    emailAccount.configuration.gptConfig.configuration.about === ECOMMERCE
  ) {
    defaulConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
  }

  const [activeKey, setActiveKey] = useState('0');
  const [bussinessConfig, setBussinessConfig] = useState({});
  const [rangeValue, setRangeValue] = useState([0]);
  const [configData, setConfigData] = useState(defaulConfig);
  const [typeOfProducts, setTypeOfProducts] = useState(null);
  const [isSameAsBussinessEmail, setIsSameAsBussinessEmail] = useState(false);
  const [isSameAsBussinessNumber, setIsSameAsBussinessNumber] = useState(false);

  const setBussinessConfigData = (infoType, key, data) => {
    const updatedData = bussinessConfig;
    if (!updatedData[infoType]) {
      updatedData[infoType] = {};
    }
    updatedData[infoType][key] = data;

    setBussinessConfig(pre => ({
      ...pre,
      ...updatedData
    }));
  };

  const handleCheckOnSaveBussinessEmail = e => {
    setIsSameAsBussinessEmail(e.target.checked);
    if (e.target.checked) {
      setBussinessConfigData(
        'Customer Service Contact Information',
        'Customer Service Email',
        watch('businessEmail')
      );
    }
  };
  const handleCheckOnSaveBussinessNumber = e => {
    setIsSameAsBussinessNumber(e.target.checked);
    if (e.target.checked) {
      setBussinessConfigData(
        'Customer Service Contact Information',
        'Customer Service Number',
        watch('businessPhoneNumber')
      );
    }
  };

  const getBussinessWithCurrentFields = () => {
    const businessConfig =
      emailAccount.configuration.gptConfig.configuration.bussinessConfig;
    const config = {};
    const formFields = Object.keys(getValues());

    // Remove old config field
    formFields.map(field => {
      const parrentKey = field.split('->')[0];
      const childKey = field.split('->')[1];
      if (!config[parrentKey]) config[parrentKey] = {};
      if (businessConfig[parrentKey] && businessConfig[parrentKey][childKey]) {
        config[parrentKey][childKey] = businessConfig[parrentKey][childKey];
      }
    });
    return config;
  };

  useEffect(() => {
    if (isSameAsBussinessEmail) {
      setBussinessConfigData(
        'Customer Service Contact Information',
        'Customer Service Email',
        watch('businessEmail')
      );
    }
  }, [watch('businessEmail')]);

  useEffect(() => {
    if (isSameAsBussinessNumber) {
      setBussinessConfigData(
        'Customer Service Contact Information',
        'Customer Service Number',
        watch('businessPhoneNumber')
      );
    }
  }, [watch('businessPhoneNumber')]);

  useEffect(() => {
    console.log(bussinessConfig);
    setValue('bussinessConfig', bussinessConfig);
  }, [bussinessConfig]);

  useEffect(() => {
    if (emailAccount.configuration) {
      const config = getBussinessWithCurrentFields();

      if (config['Product Information']) {
        const value = [];
        value.push(
          config['Product Information']['Average Price Range of Products'] || 0
        );
        setRangeValue(value);
      }

      if (
        config['Product Information'] &&
        config['Product Information']['Types of Products']
      ) {
        const multityoeOfProducts = config['Product Information'][
          'Types of Products'
        ].map(item => ({ value: item, label: item }));
        setTypeOfProducts(multityoeOfProducts);
      }
      if (
        configData['Customer Service Contact Information'] &&
        configData['Customer Service Contact Information'][
          'Customer Service Email'
        ] === watch('businessEmail')
      ) {
        setIsSameAsBussinessEmail(true);
      }

      if (
        configData['Customer Service Contact Information'] &&
        configData['Customer Service Contact Information'][
          'Customer Service Number'
        ] === watch('businessPhoneNumber')
      ) {
        setIsSameAsBussinessNumber(true);
      }
      console.log(config['Order and Shipping Information']);

      setConfigData(config);
      setBussinessConfig(config);
    }
  }, [emailAccount]);

  useEffect(() => {
    if (emailAccount.configuration) {
      if (
        emailAccount.configuration.gptConfig.configuration.about !== ECOMMERCE
      ) {
        setValue('bussinessConfig', {});
        setBussinessConfig({});
        setConfigData({});
        setRangeValue([0]);
      } else {
        setBussinessConfig(getBussinessWithCurrentFields());
      }
    }
  }, [bussinessType]);

  return (
    configData && (
      <Accordion
        activeKey={activeKey}
        onSelect={eventKey => setActiveKey(eventKey)}
      >
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Customer Service Contact Information
          </Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Row>
              <div className="col-12">
                <WizardInput
                  type="email"
                  errors={errors}
                  label="Customer Service Email"
                  name="Customer Service Email"
                  placeholder="Enter customer service email..."
                  defaultValue={
                    configData['Customer Service Contact Information']
                      ? configData['Customer Service Contact Information'][
                          'Customer Service Email'
                        ]
                      : null
                  }
                  value={isSameAsBussinessEmail ? watch('businessEmail') : null}
                  formControlProps={{
                    ...register(
                      'Customer Service Contact Information->Customer Service Email',
                      {
                        pattern: {
                          value:
                            /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                          message: 'Email must be valid'
                        },
                        validate: {
                          notSameAsEmail: value =>
                            value.toLowerCase() !==
                              watch('email').toLowerCase() ||
                            'Customer service email cannot be the same as the email'
                        }
                      }
                    ),
                    onChange: e => {
                      setIsSameAsBussinessEmail(false);
                      setBussinessConfigData(
                        'Customer Service Contact Information',
                        'Customer Service Email',
                        e.target.value
                      );
                    }
                  }}
                />
              </div>

              <Form.Group className="col-12">
                <Form.Check
                  className="pt-2"
                  type="checkbox"
                  label="Same as Business Email"
                  checked={isSameAsBussinessEmail}
                  name="Same as Business Email"
                  onChange={handleCheckOnSaveBussinessEmail}
                />
              </Form.Group>
            </Row>
            <Row>
              <div className="col-12">
                <WizardInput
                  type="text"
                  label="Customer Service Number"
                  name="Customer Service Number"
                  defaultValue={
                    configData['Customer Service Contact Information']
                      ? configData['Customer Service Contact Information'][
                          'Customer Service Number'
                        ]
                      : null
                  }
                  value={
                    isSameAsBussinessNumber
                      ? watch('businessPhoneNumber')
                      : null
                  }
                  placeholder="Enter customer service number..."
                  errors={errors}
                  formControlProps={{
                    className: 'input-spin-none',
                    ...register(
                      'Customer Service Contact Information->Customer Service Number',
                      {
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: 'Phone number must be valid'
                        }
                      }
                    ),
                    onChange: e => {
                      setIsSameAsBussinessNumber(false);
                      setBussinessConfigData(
                        'Customer Service Contact Information',
                        'Customer Service Number',
                        e.target.value
                      );
                    }
                  }}
                />
              </div>

              <Form.Group className="col-12">
                <Form.Check
                  className="pt-2"
                  type="checkbox"
                  label="Same as Business Number"
                  checked={isSameAsBussinessNumber}
                  name="Same as Business Number"
                  onChange={handleCheckOnSaveBussinessNumber}
                />
              </Form.Group>
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Product Information</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Types of Products</Form.Label>
              <Select
                options={[
                  'Clothing',
                  'Electronics',
                  'Beauty Products',
                  'Other'
                ].map(item => ({
                  label: item,
                  value: item
                }))}
                placeholder="Select..."
                isMulti
                classNamePrefix="react-select"
                value={typeOfProducts}
                {...register('Product Information->Types of Products')}
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions
                    ? selectedOptions.map(option => option.value)
                    : [];
                  setTypeOfProducts(selectedOptions);
                  setBussinessConfigData(
                    'Product Information',
                    'Types of Products',
                    selectedValues
                  );
                }}
              />
            </Form.Group>

            <WizardInput
              type="textarea"
              label="If Other Specify Below"
              defaultValue={
                configData['Product Information']
                  ? configData['Product Information']['If Other Specify Below']
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register('Product Information->If Other Specify Below'),
                onChange: e =>
                  setBussinessConfigData(
                    'Product Information',
                    'If Other Specify Below',
                    e.target.value
                  )
              }}
            />

            <Form.Group>
              <Form.Label>Number of Products</Form.Label>
              <Form.Control
                type="number"
                min={1}
                name="numberOfProducts"
                defaultValue={
                  configData['Product Information']
                    ? configData['Product Information']['Number of Products']
                    : null
                }
                {...register('Product Information->Number of Product')}
                onChange={e =>
                  setBussinessConfigData(
                    'Product Information',
                    'Number of Products',
                    e.target.value
                  )
                }
              />
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Average Price Range of Products</Form.Label>
              <FalconReactRange
                values={rangeValue}
                variant="primary"
                {...register(
                  'Product Information->Average Price Range of Products'
                )}
                onChange={val => {
                  setRangeValue(val);
                  setBussinessConfigData(
                    'Product Information',
                    'Average Price Range of Products',
                    val[0]
                  );
                }}
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Sales & Transactions</Accordion.Header>
          <Accordion.Body>
            <Form.Group>
              <Form.Label>Preferred Payment Gateways</Form.Label>

              <Form.Check
                type="radio"
                label="PayPal"
                checked={
                  bussinessConfig['Sales & Transactions']
                    ? bussinessConfig['Sales & Transactions'][
                        'Preferred Payment Gateways'
                      ] === 'PayPal'
                    : null
                }
                name="Preferred Payment Gateways"
                {...register(
                  'Sales & Transactions->Preferred Payment Gateways'
                )}
                onChange={() =>
                  setBussinessConfigData(
                    'Sales & Transactions',
                    'Preferred Payment Gateways',
                    'PayPal'
                  )
                }
              />
              <Form.Check
                type="radio"
                label="Stripe"
                checked={
                  bussinessConfig['Sales & Transactions']
                    ? bussinessConfig['Sales & Transactions'][
                        'Preferred Payment Gateways'
                      ] === 'Stripe'
                    : null
                }
                name="Preferred Payment Gateways"
                {...register(
                  'Sales & Transactions->Preferred Payment Gateways'
                )}
                onChange={() =>
                  setBussinessConfigData(
                    'Sales & Transactions',
                    'Preferred Payment Gateways',
                    'Stripe'
                  )
                }
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Refund & Exchange Policies</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <WizardInput
              type="select"
              label="Refund Policy Duration"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Refund Policy Duration'
                    ]
                  : null
              }
              options={['30 days', '60 days']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Refund Policy Duration'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Refund Policy Duration',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Conditions for Refund"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Conditions for Refund'
                    ]
                  : null
              }
              placeholder="Enter conditions for refund..."
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Conditions for Refund'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Conditions for Refund',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="select"
              label="Exchange Policy Duration"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Exchange Policy Duration'
                    ]
                  : null
              }
              options={['30 days', '60 days']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Exchange Policy Duration'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Exchange Policy Duration',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Conditions for Exchange"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Conditions for Exchange'
                    ]
                  : null
              }
              placeholder="Enter conditions for exchange..."
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Conditions for Exchange'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Conditions for Exchange',
                    e.target.value
                  )
              }}
            />

            <Form.Group>
              <Form.Label>Restocking Fees</Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                checked={
                  bussinessConfig['Refund & Exchange Policies']
                    ? bussinessConfig['Refund & Exchange Policies'][
                        'Restocking Fees'
                      ] === 'Yes'
                    : null
                }
                name="Restocking Fees"
                {...register('Refund & Exchange Policies->Restocking Fees')}
                onChange={() =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Restocking Fees',
                    'Yes'
                  )
                }
              />
              <Form.Check
                type="radio"
                label="No"
                checked={
                  bussinessConfig['Refund & Exchange Policies']
                    ? bussinessConfig['Refund & Exchange Policies'][
                        'Restocking Fees'
                      ] === 'No'
                    : null
                }
                name="Restocking Fees"
                {...register('Refund & Exchange Policies->Restocking Fees')}
                onChange={() =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Restocking Fees',
                    'No'
                  )
                }
              />
            </Form.Group>

            <WizardInput
              type="select"
              label="Refund & Exchange Process"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Refund & Exchange Process'
                    ]
                  : null
              }
              options={[
                'Online Form',
                'Email Request',
                'Customer Support Call',
                'Text'
              ]}
              placeholder="Specify how customers can initiate a refund or exchange."
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Refund & Exchange Process'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Refund & Exchange Process',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Refund & Exchange Instructions"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Refund & Exchange Instructions'
                    ]
                  : null
              }
              placeholder="Provide a brief step-by-step guide on how customers can request a refund or exchange"
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Refund & Exchange Instructions'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Refund & Exchange Instructions',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="select"
              label="Refund Processing Time"
              defaultValue={
                configData['Refund & Exchange Policies']
                  ? configData['Refund & Exchange Policies'][
                      'Refund Processing Time'
                    ]
                  : null
              }
              options={['5 days', '1 weeks', '2 weeks', '30 days']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Refund & Exchange Policies->Refund Processing Time'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Refund & Exchange Policies',
                    'Refund Processing Time',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Order and Shipping Information</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <WizardInput
              type="select"
              label="Order Tracking Method"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Order Tracking Method'
                    ]
                  : null
              }
              options={[
                'Via Our Website',
                "Via Shipper's Website",
                'Email Link',
                'Email Notification',
                'SMS Notification',
                'Other'
              ]}
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Order Tracking Method'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Tracking Method',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Order Tracking Instructions"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Order Tracking Instructions'
                    ]
                  : null
              }
              placeholder="Provide specific guidance on how customers can track their order status."
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Order Tracking Instructions'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Tracking Instructions',
                    e.target.value
                  )
              }}
            />
            <Form.Group>
              <Form.Label>Order Processing Time in Days</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Order and Shipping Information']
                    ? configData['Order and Shipping Information'][
                        'Order Processing Time in Days'
                      ]
                    : null
                }
                placeholder="Number of days it typically takes to process an order before shipping."
                {...register(
                  'Order and Shipping Information->Order Processing Time in Days'
                )}
                onChange={e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Processing Time in Days',
                    e.target.value
                  )
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Shipping Duration in Days</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  configData['Order and Shipping Information']
                    ? configData['Order and Shipping Information'][
                        'Shipping Duration in Days'
                      ]
                    : null
                }
                placeholder="Average number of days for the shipment to reach the customer."
                {...register(
                  'Order and Shipping Information->Shipping Duration in Days'
                )}
                onChange={e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Shipping Duration in Days',
                    e.target.value
                  )
                }
              />
            </Form.Group>
            <WizardInput
              type="textarea"
              label="Guidance for Order Delays"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Guidance for Order Delays'
                    ]
                  : null
              }
              placeholder="Provide information on what the user should do if their order processing is delayed."
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Guidance for Order Delays'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Guidance for Order Delays',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Guidance for Shipping Delays"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Guidance for Shipping Delays'
                    ]
                  : null
              }
              placeholder="Provide information on what the user should do if their shipment is delayed."
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Guidance for Shipping Delays'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Guidance for Shipping Delays',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="select"
              label="Order Modification Method"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Order Modification Method'
                    ]
                  : null
              }
              options={[
                'Via Website Account Dashboard',
                'Email Request to Support',
                'Customer Support Call',
                'Other'
              ]}
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Order Modification Method'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Modification Method',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Order Modification Instructions"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Order Modification Instructions'
                    ]
                  : null
              }
              placeholder="Provide specific steps on how users can modify their orders."
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Order Modification Instructions'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Modification Instructions',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="select"
              label="Order Cancellation Method"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Order Cancellation Method'
                    ]
                  : null
              }
              options={[
                'Via Website Account Dashboard',
                'Email Request to Support',
                'Customer Support Call',
                'Other'
              ]}
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Order Cancellation Method'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Cancellation Method',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Order Cancellation Instructions"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Order Cancellation Instructions'
                    ]
                  : null
              }
              placeholder="Provide specific steps on how users can cancel their orders."
              errors={errors}
              formControlProps={{
                ...register(
                  'Order and Shipping Information->Order Cancellation Instructions'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Order Cancellation Instructions',
                    e.target.value
                  )
              }}
            />
            <Form.Group>
              <Form.Label>Shipping Providers</Form.Label>

              {['FedEx', 'UPS', 'DHL'].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Order and Shipping Information']
                      ? bussinessConfig['Order and Shipping Information'][
                          'Shipping Providers'
                        ] === label
                      : null
                  }
                  name="Shipping Providers"
                  {...register(
                    'Order and Shipping Information->Shipping Providers'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Order and Shipping Information',
                      'Shipping Providers',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <Form.Group>
              <Form.Label>International Shipping</Form.Label>

              {['Yes', 'No'].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Order and Shipping Information']
                      ? bussinessConfig['Order and Shipping Information'][
                          'International Shipping'
                        ] === label
                      : null
                  }
                  name="International Shipping"
                  {...register(
                    'Order and Shipping Information->International Shipping'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Order and Shipping Information',
                      'International Shipping',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <WizardInput
              type="select"
              label="Shipping Costs"
              defaultValue={
                configData['Order and Shipping Information']
                  ? configData['Order and Shipping Information'][
                      'Shipping Costs'
                    ]
                  : null
              }
              options={['Free', 'Flat Rate', 'Variable']}
              errors={errors}
              formControlProps={{
                ...register('Order and Shipping Information->Shipping Costs'),
                onChange: e =>
                  setBussinessConfigData(
                    'Order and Shipping Information',
                    'Shipping Costs',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Warranties & Guarantees</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <WizardInput
              type="select"
              label="Product Warranty Duration"
              options={['30 days', '60 days', '90 days', '1 year']}
              defaultValue={
                configData['Warranties & Guarantees']
                  ? configData['Warranties & Guarantees'][
                      'Product Warranty Duration'
                    ]
                  : null
              }
              errors={errors}
              formControlProps={{
                ...register(
                  'Warranties & Guarantees->Product Warranty Duration'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Warranties & Guarantees',
                    'Product Warranty Duration',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Warranty Coverage Details"
              defaultValue={
                configData['Warranties & Guarantees']
                  ? configData['Warranties & Guarantees'][
                      'Warranty Coverage Details'
                    ]
                  : null
              }
              placeholder="Enter warranty coverage details..."
              errors={errors}
              formControlProps={{
                ...register(
                  'Warranties & Guarantees->Warranty Coverage Details'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Warranties & Guarantees',
                    'Warranty Coverage Details',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Marketing & Promotions</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <Form.Group>
              <Form.Label>Preferred Marketing Channels</Form.Label>

              {['Social Media', 'Email Marketing', 'PPC'].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Marketing & Promotions']
                      ? bussinessConfig['Marketing & Promotions'][
                          'Preferred Marketing Channels'
                        ] === label
                      : null
                  }
                  name="Preferred Marketing Channels"
                  {...register(
                    'Marketing & Promotions->Preferred Marketing Channels'
                  )}
                  onChange={() =>
                    setBussinessConfigData(
                      'Marketing & Promotions',
                      'Preferred Marketing Channels',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>

            <WizardInput
              type="select"
              label="Frequency of Promotions/Sales"
              defaultValue={
                configData['Marketing & Promotions']
                  ? configData['Marketing & Promotions'][
                      'Frequency of Promotions/Sales'
                    ]
                  : null
              }
              options={['Weekly', 'Monthly', 'Quarterly']}
              errors={errors}
              formControlProps={{
                ...register(
                  'Marketing & Promotions->Frequency of Promotions/Sales'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Marketing & Promotions',
                    'Frequency of Promotions/Sales',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Special Promotions or Offers"
              defaultValue={
                configData['Marketing & Promotions']
                  ? configData['Marketing & Promotions'][
                      'Special Promotions or Offers'
                    ]
                  : null
              }
              placeholder="Any ongoing promotions or special offers that the AI should be aware of."
              errors={errors}
              formControlProps={{
                ...register(
                  'Marketing & Promotions->Special Promotions or Offers'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Marketing & Promotions',
                    'Special Promotions or Offers',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>Technical Details</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <WizardInput
              type="select"
              label="E-commerce Platform Used"
              defaultValue={
                configData['Technical Details']
                  ? configData['Technical Details']['E-commerce Platform Used']
                  : null
              }
              options={['Shopify', 'WooCommerce', 'Magento']}
              errors={errors}
              formControlProps={{
                ...register('Technical Details->E-commerce Platform Used'),
                onChange: e =>
                  setBussinessConfigData(
                    'Technical Details',
                    'E-commerce Platform Used',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Integrations & Plugins Used"
              defaultValue={
                configData['Technical Details']
                  ? configData['Technical Details'][
                      'Integrations & Plugins Used'
                    ]
                  : null
              }
              name="conditionsForRefund"
              formGroupProps={{
                className: 'mb-3'
              }}
              errors={errors}
              formControlProps={{
                ...register('Technical Details->Integrations & Plugins Used'),
                onChange: e =>
                  setBussinessConfigData(
                    'Technical Details',
                    'Integrations & Plugins Used',
                    e.target.value
                  )
              }}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>Legal & Compliance</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <WizardInput
              type="textarea"
              label="Privacy Policy Details link"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Privacy Policy Details link'
                    ]
                  : null
              }
              formGroupProps={{
                className: 'mb-3'
              }}
              errors={errors}
              formControlProps={{
                ...register('Legal & Compliance->Privacy Policy Details link'),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Privacy Policy Details link',
                    e.target.value
                  )
              }}
            />

            <WizardInput
              type="textarea"
              label="Terms & Conditions Details link"
              defaultValue={
                configData['Legal & Compliance']
                  ? configData['Legal & Compliance'][
                      'Terms & Conditions Details link'
                    ]
                  : null
              }
              formGroupProps={{
                className: 'mb-3'
              }}
              errors={errors}
              formControlProps={{
                ...register(
                  'Legal & Compliance->Terms & Conditions Details link'
                ),
                onChange: e =>
                  setBussinessConfigData(
                    'Legal & Compliance',
                    'Terms & Conditions Details link',
                    e.target.value
                  )
              }}
            />
            <Form.Group>
              <Form.Label>GDPR Compliance</Form.Label>

              {['Yes', 'No'].map(label => (
                <Form.Check
                  key={label}
                  type="radio"
                  label={label}
                  checked={
                    bussinessConfig['Legal & Compliance']
                      ? bussinessConfig['Legal & Compliance']['GDPR Compliance'] ===
                        label
                      : null
                  }
                  name="GDPR Compliance"
                  {...register('Legal & Compliance->GDPR Compliance')}
                  onChange={() =>
                    setBussinessConfigData(
                      'Legal & Compliance',
                      'GDPR Compliance',
                      label
                    )
                  }
                />
              ))}
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="10">
          <Accordion.Header>Frequently Asked Questions</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-3">
            <FrequentlyAskedQuestionsField
              confg={bussinessConfig}
              setConfig={setBussinessConfig}
              data={configData['Frequently Asked Questions']}
            />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="11">
          <Accordion.Header>Additional Information</Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            <AdditionalInformationField
              confg={bussinessConfig}
              setConfig={setBussinessConfig}
              data={configData['Additional Information']}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  );
};

export default ECommerceBusinessWizard;
