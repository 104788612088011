import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import TotalEmailVolume from './TotalEmailVolume';
import AverageResponseTime from './AverageResponseTime';
import Sentiment from './Sentiment';
import Classification from './Classification';
import EmailConfigurations from './EmailConfigurations';
import EmailVolume from './EmailVolume';
import KeywordTrends from './KeywordTrends';
import AverageResolutionTime from './AverageResolutionTime ';
import ReopenedTicket from './ReopenedTicket';
import ConversationsList from './conversations-list/ConversationsList';
import TicketStatus from '../ai-support/analysis-view/ticket-status';
import TicketsVolume from '../ai-support/analysis-view/tickets-volume/TicketsVolume';

const Dashboard = () => {
  return (
    <div className="overflow-hidden">
      <Card className=" mb-3 ">
        <Card.Header className='border-bottom border-200 py-3 py-md-2'>
          <h6 className="mb-2 mb-md-0 py-md-2">
            Ticket Metrics - Weekly Overview
          </h6>
        </Card.Header>
        <Card.Body className='p-2'>
          <Row className="g-3 mb-3">
            <Col md={3}>
              <TotalEmailVolume />
            </Col>
            <Col md={3}>
              <ReopenedTicket />
            </Col>
            <Col md={3}>
              <AverageResponseTime />
            </Col>
            <Col md={3}>
              <AverageResolutionTime />
            </Col>
          </Row>
          <Row className="g-3">
            <Col md={6}>
              <Sentiment />
            </Col>
            <Col md={6}>
              <Classification />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className='mb-3 g-3'>
        <Col xxl={4}>
          <TicketStatus />
        </Col>
        <Col xxl={8}>
          <TicketsVolume />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={5}>
          <EmailConfigurations />
        </Col>
        <Col lg={7}>
          <EmailVolume />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col xs={12}>
          <ConversationsList />
        </Col>
      </Row>

      <Row>
        <Col>
          <KeywordTrends />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
