import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SwitchModeButton from './SwitchModeButton';
import GlanceView from './glance-view';
import AnalysisView from './analysis-view';
import {  useNavigate, useParams } from 'react-router-dom';
import axiosClient from 'config/axios';
import Greetings from './Greetings';
import { toast } from 'react-toastify';

const GLANCE_MODE = 'Glance';
// const ANALYSIS_MODE = 'Analysis';

const EmailDashboard = () => {
  const navigate = useNavigate();
  const { emailAccountId } = useParams();
  const [mode, setMode] = useState(GLANCE_MODE);
  const [emailAccount, setEmailAccount] = useState();

  const handleChangeMode = mode => {
    setMode(mode);
  };


  const fetchEmailAccount = async () => {
    try {
      const response = await axiosClient.get(`/email-account/${emailAccountId}`);
      setEmailAccount(response.data.emailAccount)
    } catch (error) {
      if(error.response.status === 403){
        toast.error(`Error: ${error.response.data.message}`, {
          theme: 'colored'
        });
        navigate("/errors/404")
      }
      console.log(error);
    }
  }

  useEffect(() => {
    fetchEmailAccount()
  }, [emailAccountId]);
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xs={12} className="mt-3">
          <SwitchModeButton mode={mode} setMode={handleChangeMode} />
        </Col>
            <Col xs={12} >
              <Greetings emailAccount={emailAccount}/>
            </Col>

      </Row>

      {mode === GLANCE_MODE ? <GlanceView /> : <AnalysisView />}
    </>
  );
};

export default EmailDashboard;
