import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';


function FormCheck({ title, id, inputClass, checkBoxClass, handleLegend }) {
  return (
    <Form.Check
      className={classNames(checkBoxClass, 'd-flex align-items-center mb-0')}
    >
      <Form.Check.Input
        onChange={event => handleLegend(event, title)}
        type="checkbox"
        id={id}
        defaultChecked
        className={classNames(
          'dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer',
          inputClass
        )}
      />
      <Form.Check.Label
        htmlFor={id}
        className="form-check-label lh-base mb-0 fs--4 text-500 fw-semi-bold font-base cursor-pointer"
      >
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
}

export default FormCheck;
