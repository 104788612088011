import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, getLabel } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { tooltipFormatter } from 'helpers/echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data, filter) => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: getColor('dark'),
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  },

  legend: {
    data: [
      'Positive',
      'Negative',
      'Neutral'
    ],
    textStyle: {
      color: getColor('gray-600')
    },
  },
  xAxis: {
    type: 'category',
    data: getLabel(filter),
    boundaryGap: false,
    axisLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: value => value.substring(0, 3),
      margin: 15
    },
    splitLine: {
      show: false
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: getColor('gray-200')
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      name: 'Positive',
      type: 'line',
      symbolSize: 10,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('primary')
      },
      symbol: 'circle',
      data: getLabel(filter).map(label => {
        return data['Positive']?.chartData[label] | 0
      })
    },
    {
      name: 'Negative',
      type: 'line',
      symbolSize: 10,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('danger'),
        borderWidth: 2
      },
      lineStyle: {
        color:  getColor('danger')
      },
      symbol: 'circle',
      data: getLabel(filter).map(label => {
        return data['Negative']?.chartData[label] | 0
      })
    },
    {
      name: 'Neutral',
      type: 'line',
      symbolSize: 10,
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('gray-400'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('gray-400')
      },
      symbol: 'circle',
      data: getLabel(filter).map(label => {
        return data['Neutral']?.chartData[label] | 0
      })
    },
  ],
  grid: { right: 15, left: 10, bottom: 0, top: 35, containLabel: true }
});

const SentimentAnalysisLineChart = ({data, filter}) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data, filter)}
      className="h-100 w-100"
    />
  );
};

export default SentimentAnalysisLineChart;
