import React, { useState, useEffect } from 'react';
import {
  Accordion,
  OverlayTrigger,
  Tooltip,
  Row,
  Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import WizardInput from './WizardInput';
import { Alert } from 'react-bootstrap';

const ConfigurationForm = ({
  register,
  errors,
  setValue,
  clearErrors,
  triggerErrorCheck,
  setTriggerErrorCheck,
  watch
}) => {
  console.log(setValue, clearErrors);
  

  const [activeKey, setActiveKey] = useState('0');


  // Function to create a tooltip
  const tooltip = text => (
    <Tooltip
      id={`tooltip-${text}`}
      style={{ backgroundColor: 'black', color: 'white' }}
    >
      {text}
    </Tooltip>
  );

  // Effect to handle error checking
  useEffect(() => {
    if (triggerErrorCheck) {
      if (errors.aiTone) {
        setActiveKey('0');
      } else if (
        Object.keys(errors).some(
          key => key.startsWith('question') || key.startsWith('answer')
        )
      ) {
        setActiveKey('1');
      } else if (errors.escalationEmail || errors.negativeMessageThreshold) {
        setActiveKey('2');
      }
      setTriggerErrorCheck(false); // Reset trigger
    }
  }, [triggerErrorCheck, errors]);


  return (
    <Accordion
      activeKey={activeKey}
      onSelect={eventKey => setActiveKey(eventKey)}
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          General
          <div style={{ marginLeft: '10px' }}>
            <OverlayTrigger
              placement="right"
              overlay={tooltip('This is the general section.')}
            >
              <div>
                <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
              </div>
            </OverlayTrigger>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Alert variant="info">
            <Row className="flex-column align-items-center">
              <Col xs="auto">
                <FontAwesomeIcon icon={faInfoCircle} className="mb-2" />
              </Col>
              <Col>
                <p>
                In this section, tailor your AI's tone and choose the model for your customer service assistant. For fast and efficient replies, GPT-3.5-Turbo is your go-to; it's swift but may occasionally be less precise for complex questions. If you need detailed and highly accurate responses, GPT-4 excels, especially with complicated queries, though it comes at a higher cost. 
                Set the tone to friendly, professional, or neutral to match your brand’s voice and select the model that best fits your customer service strategy.
                </p>
              </Col>
            </Row>
          </Alert>

          {/* General form fields go here */}
          <WizardInput
            type="select"
            errors={errors}
            label="AI Model"
            name="aiModel"
            options={['gpt-3.5-turbo','gpt-3.5-turbo-1106','gpt-4','gpt-4-1106-preview']}
            placeholder="Select the AI model"
            formControlProps={{
              className: 'input-spin-none',
              ...register('aiModel', {
                required: 'Please select the AI model'
              })
            }}
          />
          <WizardInput
            type="select"
            errors={errors}
            label="AI Tone*"
            name="aiTone"
            options={['Professional', 'Friendly', 'Neutral']}
            placeholder="Select the AI mood"
            formControlProps={{
              className: 'input-spin-none',
              ...register('aiTone', {
                required: 'Please select the AI mood'
              })
            }}
          />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Escalation
          <div style={{ marginLeft: '10px' }}>
            <OverlayTrigger
              placement="right"
              overlay={tooltip('This is the escalation section.')}
            >
              <div>
                <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
              </div>
            </OverlayTrigger>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {/* Escalation form fields go here */}
          <Alert variant="info">
            <Row className="flex-column align-items-center">
              <Col xs="auto">
                <FontAwesomeIcon icon={faInfoCircle} className="mb-2" />
              </Col>
              <Col>
                <p>
                  This section is for setting up escalation parameters. Please
                  provide an escalation email and define the number of negative
                  feedbacks that should trigger an escalation.
                </p>
              </Col>
            </Row>
          </Alert>
          <WizardInput
            type="email"
            errors={errors}
            label="Escalation Email*"
            name="escalationEmail"
            formControlProps={{
              ...register('escalationEmail', {
                required: 'Escalation Email is required',
                pattern: {
                  value:
                    /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                  message: 'Email must be valid'
                },
                validate: {
                  notSameAsEmail: value =>
                    value.toLowerCase() !== watch('email').toLowerCase() ||
                    'Escalation email cannot be the same as the email'
                }
              })
            }}
          />

          <WizardInput
            type="select"
            errors={errors}
            label="Negative feedback count*"
            name="negativeMessageThreshold"
            options={['2','3', '4', '5']}
            placeholder="Number of negative feedback before escalating"
            formControlProps={{
              className: 'input-spin-none',
              ...register('negativeMessageThreshold', {
                required:
                  'Please enter the number of negative feedback before escalating'
              })
            }}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ConfigurationForm;
