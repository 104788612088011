import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Card, Col, Form, Placeholder, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TicketsVolumeChart from './TicketsVolumeChart';
import { CLOSED_ESCALATION, MONTH, OPEN_ESCALATION, WEEK, YEARS } from 'data/common';
import Percentage from '../../Percentage';
import { useParams } from 'react-router-dom';
import axiosClient from 'config/axios';
import { getTotalNumber } from 'helpers/utils';
import Flex from 'components/common/Flex';

const FormCheck = ({ title, id, inputClass, checkBoxClass, handleLegend }) => {
  return (
    <Form.Check
      className={classNames(checkBoxClass, 'd-flex align-items-center mb-0')}
    >
      <Form.Check.Input
        onChange={event => handleLegend(event, title)}
        type="checkbox"
        id={id}
        defaultChecked
        className={classNames(
          'dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer',
          inputClass
        )}
      />
      <Form.Check.Label
        htmlFor={id}
        className="form-check-label lh-base mb-0 fs--2 text-500 fw-semi-bold font-base cursor-pointer"
      >
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
};

FormCheck.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  checkBoxClass: PropTypes.string,
  handleLegend: PropTypes.func
};

const TicketsCategory = ({ title, number, percentage }) => {
  return (
    <div>
      <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
        {number}
        <Percentage
          percentage={percentage}
          noBackground={true}
          className={'fs--2 ms-2 me-1'}
        />
      </h6>
      <h6 className="fs--2 text-600 mb-0 text-nowrap">{title}</h6>
    </div>
  );
};

TicketsCategory.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeColor: PropTypes.string
};

const TicketsVolume = () => {
  const { emailAccountId } = useParams();

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [ticketsStatusData, setTicketsStatusData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(WEEK);
  const chartRef = useRef(null);
  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: name
    });
  };

  const handleFiltering = e => {
    setTicketsStatusData([]);
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
  };

  const fetchData = async () => {
    try {
      const params = {
        filterType: selectedOption,
        emailAccountId: emailAccountId
      };
      const response = await axiosClient.get(`/conversation/tickets-status`, {
        params: params
      });
      setTicketsStatusData(response.data.ticketStatuses);
      setIsFirstLoading(false)
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsFirstLoading(false)
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [selectedOption, emailAccountId]);
  return (
    <Card className="h-100">
      <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
        <h6 className="mb-2 mb-md-0 py-md-2">Tickets Volume</h6>
        <Row className="g-md-0">
          <Col xs="12" lg="auto" className="d-md-flex">
            <FormCheck
              title="Open Tickets"
              id="openTickets"
              inputClass="open-tickets"
              checkBoxClass="mt-n1 mt-md-0 me-3"
              handleLegend={handleLegend}
            />
          </Col>
          <Col xs="12" lg="auto" className="d-md-flex">
            <FormCheck
              title="Closed Tickets"
              id="closedTickets"
              inputClass="closed-tickets"
              checkBoxClass="mt-n1 mt-md-0 me-3"
              handleLegend={handleLegend}
            />
          </Col>

          <Col xs="12" lg="auto"  className="d-md-flex">
            <FormCheck
              title="Open Escalation Tickets"
              id="openEscalationTickets"
              inputClass="open-escalation-tickets"
              checkBoxClass="mt-n1 mt-md-0 me-3"
              handleLegend={handleLegend}
            />
          </Col>

          <Col xs="12" lg="auto" className="d-md-flex">
            <FormCheck
              title="Closed Escalation Tickets"
              id="closedEscalationTickets"
              inputClass="closed-escalation-tickets"
              checkBoxClass="mt-n1 mt-md-0"
              handleLegend={handleLegend}
            />
          </Col>
        </Row>
      </Card.Header>

      {isFirstLoading ? (
        <Placeholder as={Card.Text} animation="glow">
          <div
            className="w-100 placeholder rounded"
            style={{ height: 550 }}
          ></div>
        </Placeholder>
      ) : (
        <Card.Body className="scrollbar overflow-y-hidden d-flex flex-column justify-content-between">
          {isLoading ? (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ height: 550 }}
              className="position-absolute start-50 top-50 translate-middle"
            >
              <Spinner animation="border" role="status"></Spinner>
              <span className="mt-2">Loading...</span>
            </Flex>
          ) : !hasError && ticketsStatusData ? (
            <>
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <TicketsCategory
                    title="Total Open Tickets"
                    number={getTotalNumber(
                      ticketsStatusData['Open']?.chartData
                    )}
                    percentage={ticketsStatusData['Open']?.percentage || 0}
                    icon="caret-up"
                    badgeColor="text-primary"
                  />
                  <div
                    className="bg-200 mx-3"
                    style={{ height: '24px', width: '1px' }}
                  ></div>
                </div>
                <div className="d-flex align-items-center">
                  <TicketsCategory
                    title="Total Closed Tickets"
                    number={getTotalNumber(
                      ticketsStatusData['Closed']?.chartData
                    )}
                    percentage={ticketsStatusData['Closed']?.percentage || 0}
                    icon="caret-down"
                    badgeColor="text-danger"
                  />
                  <div
                    className="bg-200 mx-3"
                    style={{ height: '24px', width: '1px' }}
                  ></div>
                </div>
                <div className="d-flex align-items-center">
                  <TicketsCategory
                    title="Total Closed Tickets"
                    number={getTotalNumber(
                      ticketsStatusData[OPEN_ESCALATION]?.chartData
                    )}
                    percentage={ticketsStatusData[OPEN_ESCALATION]?.percentage || 0}
                    icon="caret-down"
                    badgeColor="text-danger"
                  />
                  <div
                    className="bg-200 mx-3"
                    style={{ height: '24px', width: '1px' }}
                  ></div>
                </div>
                <TicketsCategory
                  title="Total Escalated Tickets"
                  number={getTotalNumber(
                    ticketsStatusData[CLOSED_ESCALATION]?.chartData
                  )}
                  percentage={ticketsStatusData[CLOSED_ESCALATION]?.percentage || 0}
                  icon="caret-down"
                  badgeColor="text-warning"
                />
              </div>
              <TicketsVolumeChart
                data={ticketsStatusData}
                ref={chartRef}
                filter={selectedOption}
              />
            </>
          ) : (
            <div className="py-5 m-auto">No data</div>
          )}
        </Card.Body>
      )}
      <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <Form.Select
              value={selectedOption}
              onChange={handleFiltering}
              size="sm"
            >
              <option value={WEEK}>Week</option>
              <option value={MONTH}>Month</option>
              <option value={YEARS}>Years</option>
            </Form.Select>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default TicketsVolume;
