import React, { useRef } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import greetingsBg from '../../../assets/img/illustrations/ticket-greetings-bg.png';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { industryIcon } from 'data/common';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Greetings({ emailAccount }) {
  const windowWidth = useRef(window.innerWidth);

  return !emailAccount ? (
    <Placeholder as={Card.Text} animation="glow">
      <div
        className="w-100 placeholder rounded"
        style={{ height: windowWidth.current < 768 ? '110px' : '110px' }}
      ></div>
    </Placeholder>
  ) : (
    <Card className="bg-light shadow-none">
      <Background image={greetingsBg} className="bg-card d-none d-sm-block" />

      <Card.Header className="d-flex align-items-center z-index-1 p-0 gap-3 p-4">
        <FontAwesomeIcon
          icon={
            industryIcon[
              emailAccount?.configuration?.gptConfig.configuration.about
            ]
          }
          className="fs-5 text-primary"
        />

        <div className="">
          <h4 className="mb-0 text-primary fw-bold">
            {emailAccount?.name}
            <span className="text-info fw-medium"> AI support</span>
            <Link to={`/email/${emailAccount?.id}/update`}>
              <FontAwesomeIcon
                icon={faPen}
                className="text-info fs-1 ms-2 cursor-pointer"
              />
            </Link>
          </h4>
          <h6 className="mb-1 text-primary">
            {emailAccount?.configuration?.gptConfig.configuration.about}
          </h6>
        </div>
      </Card.Header>
    </Card>
  );
}

export default Greetings;
