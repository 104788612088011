import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';

const pointColor = [
  '#2A7BE4',
  '#1956A6',
  '#195099',
  '#2A7BE4',
  '#2A7BE4',
  '#1956A6',
  '#195099',
  '#9DBFEB',
  '#2A7BE4',
  '#195099',
  '#9DBFEB',
  '#2A7BE4',
  '#195099',
  '#9DBFEB',
  '#0F67D9',
  '#7FA5D5',
  '#8ABBFB',
  '#1956A6',
  '#85B6F5',
  '#6486B4',
  '#2A7BE4',
  '#68A0E9',
  '#74A2DE',
  '#4E7AB4',
  '#71AFFF'
];

export const CloudChart = ({ data }) => {
  const [animatedNodes, setAnimatedNodes] = useState([]);
  const nodes = data;

  const getFontSize = (v, width) => {
    if (width < 30) return '8px';
    else if (width < 40) return '12px';
    return '14px';
  };

  const radiusScale = value => {
    const fx = d3
      .scaleSqrt()
      .range([15, 55])
      .domain([
        0.95 *
          d3.min(nodes, item => {
            return item.v;
          }),
        5 *
          d3.max(nodes, item => {
            return item.v;
          })
      ]);
    return fx(value);
  };

  // Tooltip
  const showTooltip = function (e, item, index) {
    e.target.classList.add('opacity-75');
    d3.select('.d3-tooltip').transition().duration(200);
    d3.select('.d3-tooltip')
      .style('opacity', 1)
      .style('border', `1px solid ${pointColor[index]}`)
      .text(item.name + ': ' + item.v)
      .style('left', e.clientX - 40 + 'px')
      .style('top', e.clientY - 40 + 'px');
  };

  const moveTooltip = function (e) {
    e.target.classList.add('opacity-75');
    d3.select('.d3-tooltip')
      .style('opacity', 1)
      .style('left', e.clientX - 40 + 'px')
      .style('top', e.clientY - 40 + 'px');
  };

  const hideTooltip = function (e) {
    e.target.classList.remove('opacity-75');
    d3.select('.d3-tooltip').transition().duration(200).style('opacity', 0);
  };

  useEffect(() => {
    const simulation = d3
      .forceSimulation()
      .velocityDecay(0.4)
      .force('x', d3.forceX().strength(0.02))
      .force('y', d3.forceY().strength(0.07))
      .force(
        'collide',
        d3.forceCollide(d => radiusScale(d.v) + 15)
      );

    simulation.on('tick', () => {
      setAnimatedNodes([...simulation.nodes()]);
    });

    simulation.nodes([...nodes]);

    simulation.alpha(0.1).restart();

    return () => simulation.stop();
  }, [nodes]);
  const windowWidth = useRef(window.innerWidth);
  return (
    <div
      className="position-relative w-100"
      style={{ height: windowWidth.current < 768 ? '15rem' : '40rem' }}
    >
      <div className="d3-tooltip"></div>
      <svg className="packed-bubble-svg h-100 w-100" viewBox={'0 0 460 400'}>
        <>
          {animatedNodes.map((node, index) => (
            <g
              key={index}
              textAnchor="middle"
              transform={`translate(${460 / 2 + node.x}, ${400 / 2 + node.y})`}
              onMouseOver={e => showTooltip(e, node, index)}
              onMouseMove={e => moveTooltip(e)}
              onMouseLeave={e => hideTooltip(e)}
            >
              <circle
                r={radiusScale(node.v) * 1.75 }
                fill={pointColor[index % (pointColor.length - 1)]}
                strokeWidth="0"
              />
              {node.name.split(/ /g).length === 2 ? (
                node.name.split(/ /g).map((item, index) => (
                  <text
                    key={index}
                    dy={index ? 10 : -4}
                    fill="#fff"
                    textAnchor="middle"
                    fontSize={getFontSize(node.v, radiusScale(node.v) * 1.5)}
                    fontWeight="normal"
                    style={{
                      pointerEvents: 'none'
                    }}
                  >
                    {item}
                  </text>
                ))
              ) : (
                <text
                  dy="4"
                  fill="#fff"
                  textAnchor="middle"
                  fontSize={getFontSize(node.v, radiusScale(node.v) * 1.5)}
                  fontWeight="normal"
                  style={{
                    pointerEvents: 'none'
                  }}
                >
                  {node.name.length > 10 ? [node.name.slice(0,10), "..."].join("") : node.name }
                </text>
              )}
            </g>
          ))}
        </>
      </svg>
    </div>
  );
};
