import Flex from 'components/common/Flex';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React, { useEffect, useState } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import Background from 'components/common/Background';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import { useParams } from 'react-router-dom';
import axiosClient from 'config/axios';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const AverageResponseTime = () => {
  const { emailAccountId } = useParams();
  const [data, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [emailAccountId]);

  const fetchData = async () => {
    try {
      const params = { emailAccountId: emailAccountId };
      const response = await axiosClient.get(
        `/conversation-message/average-response-time`,
        { params }
      );
      setDate(response.data.averageResponseTime);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };
  return isLoading ? (
    <Placeholder as={Card.Text} animation="glow">
      <div className="w-100 placeholder rounded" style={{ height: 150 }}></div>
    </Placeholder>
  ) : (
    <Card className="h-md-100">
      <Background image={bg2} />
      <Card.Header className="pb-0 d-flex gap-2">
        <h6 className="mb-0 mt-2">Average Response Time</h6>
      </Card.Header>

      <Card.Body as={Flex}>
        {!hasError && data ? (
          <div className="d-flex gap-2 mt-auto me-auto">
            <h2 className="mb-1 fw-normal lh-1 text-info">
              {data.toFixed(2)}s
            </h2>
          </div>
        ) : (
          <div className="py-4 m-auto">No data</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default AverageResponseTime;
