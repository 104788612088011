import Flex from 'components/common/Flex';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap';
import { getColor, getTotalNumber } from 'helpers/utils';
import BasicECharts from 'components/common/BasicEChart';
import { daysShot } from 'data/common';
import Percentage from '../ai-support/Percentage';
import axiosClient from 'config/axios';
import { useParams } from 'react-router-dom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    formatter: '{b0} : {c0}',
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1
  },
  xAxis: {
    type: 'category',
    data: daysShot,
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      center: ['70%', '50%'],
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        borderRadius: 10
      },
      barWidth: '7px',
      itemStyle: {
        borderRadius: 10,
        color: getColor('primary')
      },
      data: daysShot.map(day => ({
        value: data[day] || 0,
        name: day
      })),
      z: 10
    }
  ],
  grid: { right: 10, left: 10, top: 0, bottom: 0 }
});

const TotalEmailVolume = () => {
  const { emailAccountId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setDate] = useState(null);
  const [dataMonthly, setDataMonthly] = useState(null);
  const [dataYearly, setDataYearly] = useState(null);
  const [dataAllTime, setDataAllTime] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    fetchDataByMonth();
    fetchDataByYear();
    fetchDataAllTime()
  }, [emailAccountId]);

  const fetchData = async () => {
    try {
      const params = { filterType: 'week', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/email-volume`, {
        params
      });
      setDate(response.data);
      setIsLoading(false);
      setHasError(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      console.log(error);
    }
  };
  const fetchDataByMonth = async () => {
    try {
      const params = { filterType: 'month', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/email-volume`, {
        params
      });
      const data = response.data;
      const total = Object.values(data.chartData).reduce((a, b) => a + b, 0);
      setDataMonthly({
        total: total,
        percentage: data.percentage,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataByYear = async () => {
    try {
      const params = { filterType: 'months', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/email-volume`, {
        params
      });
      const data = response.data;
      const total = Object.values(data.chartData).reduce((a, b) => a + b, 0);
      setDataYearly({
        total: total,
        percentage: data.percentage,
      });

    } catch (error) {
      console.log(error);
    }
  };
  const fetchDataAllTime = async () => {
    try {
      const params = { filterType: 'all', emailAccountId: emailAccountId };
      const response = await axiosClient.get(`/conversation/email-volume`, {
        params
      });
      const data = response.data;
      const total = Object.values(data.chartData).reduce((a, b) => a + b, 0);
      setDataAllTime({
        total: total,
        percentage: data.percentage,
      });

    } catch (error) {
      console.log(error);
    }
  };
  console.log(isLoading);
  return isLoading ? (
    <Placeholder as={Card.Text} animation="glow">
      <div className="w-100 placeholder rounded" style={{ height: 150 }}></div>
    </Placeholder>
  ) : (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex flex-column pt-2">
        <div className='d-flex justify-content-between col-8 ms-auto'>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Month</Tooltip>
            }
          >
            <h6>M: {dataMonthly?.total}</h6>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Year to date</Tooltip>
            }
          >
          <h6>YTD: {dataYearly?.total}</h6>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>All time</Tooltip>
            }
          >
          <h6>AT: {dataAllTime?.total}</h6>
          </OverlayTrigger>
        </div>
        <div className='d-flex gap-2 align-items-center'>
          <h6 className="mb-0 mt-1">Total Ticket Volume</h6>
          {data && <Percentage percentage={data.percentage} />}
        </div>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        {!hasError && data ? (
          <>
            <div className="d-flex gap-2">
              <h2 className="fw-normal text-700 mb-1 lh-1">
                {getTotalNumber(data.chartData)}
              </h2>
            </div>

            <BasicECharts
              echarts={echarts}
              options={getOptions(data.chartData)}
              style={{ width: '8.5rem', height: 60 }}
            />
          </>
        ) : (
          <div className="m-auto py-4">No data</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default TotalEmailVolume;
